<template>
  <span class="tag-tradein-cart-item-status">
    <Tag
      :text="$t(`trade_in_carts.status.${status}.title`)"
      :color="statusColor[status] || 'grey'"
    />
    <ToolTip :infos="$t(`trade_in_carts.status.${status}.description`)" />
  </span>
</template>

<script>
import Tag from "@/components/ui/tag/Tag";
import ToolTip from "@/components/ui/tootip/ToolTip";

export default {
  components: { ToolTip, Tag },
  name: "TagTradeinCartItemStatus",
  props: {
    status: String,
  },
  data() {
    return {
      statusColor: {
        draft: "grey",
        validated: "orange",
        shipped: "blue",
        received: "blue2",
        controlling: "pink",
        accepted: "green2",
        refused: "red2",
        expired:"grey",
        store_controlled: "grey",
        partially_accepted: "yellow",
        partially_refused: "red2",
      },
    };
  },
};
</script>

<style lang="scss">
.tag-tradein-cart-item-status {
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible !important;

  .tooltip__infos {
    padding: 10px;
    left: unset;
    right: 0;
    transform: translateX(10px);

    &:after {
      right: 15px;
      left: unset;
    }
  }

  .tooltip i.dark {
    background-color: var(--color-bg-very-light);
    border: var(--border-1);
    color: #000;
  }
}
</style>
