var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-select",class:{
    'no-choice': _vm.noChoice,
    'has-border': _vm.border,
    'is-disabled': _vm.disabled,
  },on:{"mouseleave":function($event){_vm.open = false}}},[(_vm.label)?_c('span',{staticClass:"field-select__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"field-select__selected",class:{ open: _vm.open },attrs:{"data-testid":`filter-type-select-${_vm.placeholder}`},on:{"click":function($event){_vm.open = _vm.noChoice ? false : !_vm.open}}},[_c('div',{staticClass:"field-select__content"},[(_vm.icon)?_c('SvgIcon',{staticClass:"select-icon",attrs:{"name":_vm.icon}}):_vm._e(),_c('span',{staticClass:"field-select__placeholder"},[_vm._v(_vm._s(_vm.placeholder))])],1),(!_vm.noChoice)?_c('SvgIcon',{attrs:{"name":"chevron"}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noChoice && _vm.open),expression:"!noChoice && open"}],staticClass:"field-select__items"},_vm._l((_vm.optionsSorted),function(option,i){return _c('div',{key:i,class:{
        'is-selected': Array.isArray(_vm.selected)
          ? _vm.selected.includes(option.id) || _vm.selected.includes(`${_vm.options.id}`)
          : option.id == _vm.selected,
        'is-disabled': option.disabled,
      },attrs:{"data-testid":`filter-type-option-${option.label}`},on:{"click":function($event){$event.preventDefault();return _vm.select(option.id)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }