import listRoutes from "@/api/list-routes";
import router from "@/router";
import API from "@/api/api-admin";

let userInStorage = window.localStorage.getItem("user");
userInStorage = userInStorage ? JSON.parse(userInStorage) : null;

// Gérer l'évolution des objets user
function formatUser(user) {
  const cmsMapping = {
    null: ["FAUME", "SHOPIFY"],
    faume: ["FAUME"],
    shopify: ["SHOPIFY"],
  };

  if (user) user.cms = cmsMapping[user.cms];
  return user;
}
// Gérer l'évolution des objets user

const state = {
  user: userInStorage,
  temporaryUser: null,
};

const mutations = {
  SET_USER(state, user) {
    if (user) {
      state.user = formatUser(user);
      window.localStorage.setItem("user", JSON.stringify(state.user));
    } else {
      state.user = null;
      window.localStorage.removeItem("user");
    }
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_TEMPORARY_USER(state, user) {
    console.log("SET_TEMPORARY_USER mutation avec:", {
      id: user?.id,
      currency: user?.currency,
      email: user?.email,
    });
    state.temporaryUser = formatUser(user);
    console.log("Nouveau temporaryUser défini:", {
      id: state.temporaryUser?.id,
      currency: state.temporaryUser?.currency,
      email: state.temporaryUser?.email,
    });
  },
};

const actions = {
  login({ commit }, user) {
    user = { ...user };
    return new Promise((resolve, reject) => {
      API.post(listRoutes.login, user)
        .then((res) => res.data)
        .then((data) => {
          if (data) {
            commit("SET_USER", data);
            resolve();
          } else {
            reject("Adresse email ou mot de passe incorrecte");
          }
        })
        .catch((err) =>
          reject(err || "Adresse email ou mot de passe incorrecte")
        );
    });
  },
  forgotPassword(_, user) {
    return new Promise((resolve, reject) => {
      API.post(listRoutes.passwordForgot, user)
        .then((res) => res.data)
        .then((data) => resolve(data.message))
        .catch((err) =>
          reject(err || "Aucun compte n'est associé à cet email")
        );
    });
  },
  resetPassword(_, user) {
    return new Promise((resolve, reject) => {
      API.post(
        listRoutes.passwordReset.replace(":id", router.currentRoute.params.id),
        user
      )
        .then((res) => res.data)
        .then((data) => resolve(data.message))
        .catch((err) => reject(err || "Une erreur est survenu"));
    });
  },
  logout({ commit }) {
    window.localStorage.removeItem("user");
    commit("SET_USER", null);
  },
  async setTemporaryUser({commit, dispatch}, user) {
    try {
        const response = await API.post(listRoutes.temporaryUser.replace(':id', user.id));
        const mergedUser = {...user, ...response.data};
        
        // 1. Mettre à jour temporaryUser
        commit('SET_TEMPORARY_USER', mergedUser);
        
        // 2. Mettre à jour la currency si présente
        if (mergedUser.currency) {
            await dispatch('updateCurrency', mergedUser.currency, { root: true });
        }
    } catch (error) {
        console.error('Erreur dans setTemporaryUser:', error);
        throw error;
    }
},

async unsetTemporaryUser({commit, dispatch, state}) {
    // Revenir à la devise de l'utilisateur principal
    const mainUserCurrency = state.user?.currency;
    
    commit('SET_TEMPORARY_USER', null);
    
    if (mainUserCurrency) {
        await dispatch('updateCurrency', mainUserCurrency, { root: true });
    }
}
};

const getters = {
  access_token: (state) =>
    state.user
      ? state[state.temporaryUser ? "temporaryUser" : "user"].access_token
      : null,
  isAdmin: (state) =>
    state.user
      ? state[state.temporaryUser ? "temporaryUser" : "user"].roles.includes(
          "ROLE_ADMIN"
        )
      : false,
  roles: (state) =>
    state.user
      ? state[state.temporaryUser ? "temporaryUser" : "user"].roles
      : [],
  isClient: (state) =>
    state.user
      ? state[state.temporaryUser ? "temporaryUser" : "user"].roles.includes(
          "ROLE_CLIENT"
        )
      : false,
  storeLink: (state) =>
    state.user
      ? state[state.temporaryUser ? "temporaryUser" : "user"].store_link
      : null,
  userClients: (state) =>
    state.user
      ? state[state.temporaryUser ? "temporaryUser" : "user"].clients
      : null,
  userClientsList: (state) => {
    const listClientId = state[
      state.temporaryUser ? "temporaryUser" : "user"
    ].clients?.map((x) => x.id);
    if (!listClientId?.length) return state.clients;
    return state.clients.filter((client) => listClientId.includes(client.id));
  },
  roleIsAllowed: (state) => (allowedRoles) => {
    const roles =
      state[state.temporaryUser ? "temporaryUser" : "user"].roles || [];
    if (!roles?.length || !allowedRoles?.length) return false;
    return roles.some((role) => allowedRoles.includes(role));
  },
  activeUser: (state) => state.temporaryUser || state.user,
  temporaryUserFullName: (state) =>
    state.temporaryUser
      ? `${state.temporaryUser.firstname} ${state.temporaryUser.lastname}`
      : "",
};

const user = {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default user;
