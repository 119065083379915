<template>
    <div class="home-page">

        <PageHeader :title="$t('home.title')">
            <template v-slot:actions>
                <ButtonLink size="m" icon="user" color="black" :to="{name: 'profile'}">{{ $t('home.profile') }}</ButtonLink>
            </template>
        </PageHeader>

        <GridContainer>

            <div v-for="(menu, i) in allowedMenu.slice(1)" :key="i">
                <GridHeader :title="$t(`menu.${menu.meta.title}`)" size="xs"/>

                <Checkerboard>

                    <CheckerboardItem v-if="!menu.links">
                        <TextTitle size="s">{{ $t(`menu.${menu.meta.title}`) }}</TextTitle>
                        <TextSimple>{{ $t(`home.descriptions.${menu.name}`) }}</TextSimple>
                        <ButtonLink size="s" icon="right" color="grey" :to="{name: menu.name}">{{ $t('home.button') }}</ButtonLink>
                    </CheckerboardItem>

                    <CheckerboardItem
                            v-for="(link, y) in menu.links"
                            :key="y"
                            :title="$t(`menu.${link.meta.title}`)"
                            titleSize="s"
                            :set="descKey = `home.descriptions.${link.name}`"
                            :subtitle="descKey !== $t(descKey) ? $t(descKey) : null"
                    >
                        <ButtonLink size="s" icon="right" color="grey" :to="{name: link.name}">{{ $t('home.button') }}</ButtonLink>
                    </CheckerboardItem>

                    <CheckerboardItem v-if="storeLink && menu.name === 'resales'">
                        <TextTitle size="s">{{ $t(`menu.resaleForm`) }}</TextTitle>
                        <TextSimple>{{ $t(`home.descriptions.resaleForm`) }}</TextSimple>
                        <ButtonLink size="s" icon="right" color="grey" :to="storeLink">{{ $t('home.button') }}</ButtonLink>
                    </CheckerboardItem>

                    <CheckerboardItem v-if="false">
                        <TextTitle size="s">{{ $t("menu.faq") }}</TextTitle>
                        <TextSimple>{{ $t("home.descriptions.faq") }}</TextSimple>
                        <ButtonLink size="s" icon="right" color="grey" to="https://faume.zendesk.com/hc/fr">{{ $t('home.button') }}</ButtonLink>
                    </CheckerboardItem>

                </Checkerboard>

            </div>

        </GridContainer>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import PageHeader from '@/components/ui/page/PageHeader';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import ButtonLink from '@/components/ui/button/ButtonLink';
import TextSimple from '@/components/ui/text/TextSimple';
import TextTitle from '@/components/ui/text/TextTitle';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridHeader from '@/components/ui/grid/GridHeader';

export default {
    name: 'HomePage',
    components: {GridHeader, GridContainer, TextTitle, TextSimple, ButtonLink, CheckerboardItem, Checkerboard, PageHeader},
    computed: {
        ...mapState(['user']),
        ...mapGetters(['allowedMenu', 'storeLink'])
    }
}
</script>

<style lang="scss">
.home-page {
    .grid-header {
        padding-bottom: 10px;
        position: sticky;
        top: 0;
        padding-top: 10px;
        z-index: 2;
        background: var(--color-bg-light);
        margin-bottom: 10px;
    }
}
</style>