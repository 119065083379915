<template>
    <TemplateClient :subtitle="$t('client.recommerce.title')">

        <CheckerboardContainer v-if="client">
            <CheckerboardItem v-for="(item, i) in allowed"
                              :key="i"
                              :title="$t(item.title)"
                              title-size="s"
                              :route="{name: item.route}"
                              :image="item.icon"/>
        </CheckerboardContainer>

    </TemplateClient>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CheckerboardContainer from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import TemplateClient from '@/components/templates/TemplateClient';

export default {
    name: 'ClientRecommerce',
    components: {TemplateClient, CheckerboardItem, CheckerboardContainer},
    data() {
        return {
            stores: null,
            list: [
                {
                    title: 'client.config_text',
                    icon: '/img/goldenmetrics/translation.svg',
                    route: 'client-translations'
                },
                {
                    title: 'client.config_email',
                    icon: '/img/goldenmetrics/email.svg',
                    route: 'client-recommerce-emails'
                },
                {
                    title: 'client.mailbuilder',
                    icon: '/img/goldenmetrics/email.svg',
                    route: 'client-new-recommerce-emails'
                },
                {
                    title: 'market.btnConfigMarket',
                    icon: '/img/goldenmetrics/world.svg',
                    route: 'config-website-markets'
                },
                /*
                 {
                    title: 'client.seo.title',
                    icon: '/img/goldenmetrics/details.svg',
                    route: 'client-seo'
                },
                    {
                    title: 'client.visual_content.title',
                    icon: '/img/goldenmetrics/camera.svg',
                    route: 'client-images'
                },
                {
                    title: 'client.payment.title',
                    icon: '/img/goldenmetrics/payment.svg',
                    route: 'client-payment'
                }
                 */
            ]
        }
    },
    computed: {
        ...mapState(['client']),
        ...mapGetters(['roles']),
        allowed() {
            return this.list.filter(item => this.allRoutes.find(route => route.name === item.route)?.roles.find(role => this.roles.includes(role)))
        },
        allRoutes() {
            return this.$router.options.routes.map(route => ({name: route.name, roles: route.meta?.roles || []}))
        }
    }
}
</script>
