// mixins/currencyRouteMixin.js
export default {
  computed: {
    currentCurrency() {
      // Obtenir la currency depuis le temporaryUser d'abord, sinon utiliser celle du store currency
      const temporaryUser = this.$store.state.user.temporaryUser;
      return temporaryUser?.currency || this.$store.state.currency.currency;
  }
  },
  methods: {
    async ensureCurrencyInQuery() {
      if (!this.currentCurrency) return;
      
      if (this.$route.query["currency:in"] !== this.currentCurrency) {
          const newQuery = {
              ...this.$route.query,
              "currency:in": this.currentCurrency,
              page: this.$route.query.page || "1",
              limit: this.$route.query.limit || "10"
          };

          await this.$router.replace({ query: newQuery });
      }
  }
  },
  watch: {
    currentCurrency: {
        handler(newCurrency) {
            if (newCurrency) {
                this.ensureCurrencyInQuery();
            }
        },
        immediate: true
    },
    '$route': {
        handler() {
            this.ensureCurrencyInQuery();
        },
        deep: true
    }
},
  created() {
    this.ensureCurrencyInQuery();
  },
};