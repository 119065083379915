<template>
  <div class="menu">
    <ul class="menu__list">
      <li class="menu__currency" v-if="hasAdminAccess">
        <FieldSelect
          :selected="selectedLanguage"
          :options="currencyOptions"
          :border="true"
          icon="wallet"
          @input="handleCurrencyChange"
          :loading="loadingCurrencies"
        />
      </li>
      <li v-for="link in allowedMenu" :key="link.name">
        <LayoutMenuLink
          v-if="link.links?.length === 1"
          :to="link.links[0].name"
          :title="link.meta.title"
          :icon="link.meta.icon"
          @click.native="$emit('close')"
        />
        <LayoutMenuCollapse
          v-else-if="link.links"
          :title="link.meta.title"
          :icon="link.meta.icon"
        >
          <ul>
            <li v-for="(sublink, i) in link.links" :key="i">
              <LayoutMenuLink
                :to="sublink.name"
                :title="sublink.meta.title"
                @click.native="$emit('close')"
                :disabled="isLinkDisabled(sublink.name)"
                :data-testid="`${sublink.name}-submenu`"
              />
            </li>
          </ul>
        </LayoutMenuCollapse>
        <LayoutMenuLink
          v-else
          :to="link.name"
          :title="link.meta.title"
          :icon="link.meta.icon"
          @click.native="$emit('close')"
        />
      </li>
      <li v-if="storeLink">
        <LayoutMenuLink
          :href="storeLink"
          title="resaleForm"
          icon="form"
          @click.native="$emit('close')"
        />
      </li>
      <li v-if="false">
        <LayoutMenuLink
          href="https://faume.zendesk.com/hc/fr"
          title="faq"
          icon="faq"
          @click.native="$emit('close')"
        />
      </li>
      <li class="menu__academy">
        <LayoutMenuLink :href="academyUrl" title="academy" icon="academy" />
      </li>
    </ul>
  </div>
</template>

<script>
import LayoutMenuLink from "@/components/ui/layouts/LayoutMenuLink";
import LayoutMenuCollapse from "@/components/ui/layouts/LayoutMenuCollapse";
import FieldSelect from "@/components/ui/form/fields/FieldSelect.vue";
import { mapState, mapGetters } from "vuex";
import PublicAPI from "@/api/api-public";

export default {
  name: "LayoutMenu",
  components: { LayoutMenuCollapse, LayoutMenuLink, FieldSelect },
  data() {
    return {
      selectedLanguage: null,
      currencies: [],
      loadingCurrencies: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["allowedMenu", "storeLink", "roleIsAllowed"]),

    hasAdminAccess() {
      return this.roleIsAllowed(["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]);
    },

    academyUrl() {
      return this.$i18n.locale === "en"
        ? "https://www.notion.so/Faume-Academy-EN-7e2967f257104a06bd2a36e18ac50f23"
        : "https://www.notion.so/faume/Faume-Academy-c4947dfa7cfc4776810bcecef65d50b9";
    },
    currencyOptions() {
      return this.currencies.map((currency) => ({
        id: currency.code,
        label: currency.name,
      }));
    },
  },
  methods: {
    handleCurrencyChange(value) {
      const currency = this.currencies.find((c) => c.code === value);
      if (currency) {
        this.selectedLanguage = currency.code;
        this.$store.dispatch("updateCurrency", currency.code);
      }
    },
    async fetchCurrencies() {
      try {
        this.loadingCurrencies = true;
        const response = await PublicAPI.get("currencies");
        this.currencies = response.data;

        await this.$store.dispatch("initCurrency");

        // Récupère la devise du store
        const currentCurrency = this.$store.state.currency.currency;

        // Si on a une devise, on la stocke dans le localStorage
        if (currentCurrency) {
          localStorage.setItem("userCurrency", currentCurrency);
        } else if (this.currencies.length > 0) {
          // Si pas de devise dans le store, on prend la première devise disponible
          const defaultCurrency = this.currencies[0].code;
          localStorage.setItem("userCurrency", defaultCurrency);
          await this.$store.dispatch("updateCurrency", defaultCurrency);
        }

        this.selectedLanguage = currentCurrency || this.currencies[0]?.code;
      } catch (error) {
        console.error("Erreur lors de la récupération des devises:", error);
        this.currencies = [];
      } finally {
        this.loadingCurrencies = false;
      }
    },
    isLinkDisabled(routeName) {
      const disabledRoutes = [
        // Orders routes
        "orders",
        "orders-articles",
        "orders-returned",
        "orders-refunded",
        "orders-return",
        "orders-returns-treated",

        // Resales routes
        "resales-all",
        "resales-waiting-review",
        "resales-credited",
        "resales-waiting-shooting",
        "resales-waiting-publication",
        "resales-shipping-create",
        "resales-stocks",
        "resale",
        "resales-photos",

        // Customers routes
        "customers-all",
        "customer",
        "customers-wallets",
        "wallet",
        "wallet-add",

        // Promotions routes
        "vouchers",
        "voucher",

        // Dashboard routes
        "dashboard-performances",
        // "dashboard-sellers",
        // "dashboard-buyers",
        "dashboard-operations",

        // Warehouse routes
        "warehouse-inventory",
        "warehouse-bills",
        "warehouse-contact",
        "warehouse-articles",
        "warehouse-inventory-refused",

        // Monitoring routes
        "monitoringTests",
        "test",
        "monitoringDatas",
        "data",
      ];

      return (
        this.selectedLanguage !== "EUR" && disabledRoutes.includes(routeName)
      );
    },
  },
  async created() {
    await this.fetchCurrencies();
  },
  watch: {
    "$store.state.currency.currency": {
      immediate: true,
      handler(newCurrency) {
        if (newCurrency) {
          this.selectedLanguage = newCurrency;
        }
      },
    },
  },
  // Nouveau watcher pour le temporaryUser
  "$store.state.user.temporaryUser": {
    handler(newTemporaryUser) {
      console.log("Changement de temporaryUser détecté:", {
        id: newTemporaryUser?.id,
        currency: newTemporaryUser?.currency,
      });
      if (newTemporaryUser) {
        // Réinitialiser la devise quand on change de temporaryUser
        this.$store.dispatch("initCurrency");
      }
    },
  },
};
</script>

<style lang="scss">
.menu {
  padding: 30px 0;
  flex-grow: 1;
  overflow-y: auto;

  @media screen and (min-width: 1025px) {
    border-top: var(--border-1);
  }

  @media screen and (max-width: 1024px) {
    .layout-menu-link {
      min-height: 40px;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-bg-light);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-bg-grey);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-bg-grey);
  }
  & > p {
    margin-bottom: 10px;
    margin-left: 7px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    & > li {
      & > .router-link-active {
        background-color: var(--color-bg-grey);
      }
    }
  }

  &__academy {
    margin-top: auto;
  }
}
</style>
