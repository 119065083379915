<template>
    <TemplateTable
            :title="$t('menu.orders-refunded')"
            :text="$t('ordersRefunded.description')"
            name="ordersRefunded"
            :url="$listRoutes.ordersRefunded"
            :table="ordersRefunded"
            :filters="ordersRefundedFilters"
            :hide-export="true"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
    name: 'OrdersPage',
    mixins: [currencyRouteMixin],

    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'order-refunded', size: 'xs'},
                {key: 'shopifyId', type: 'copy', size: 'm'},
                {key: 'number', type: 'copy', size: 'm'},
                {key: 'price', translationKey: 'initialPrice', type: 'money', size: 's'},
                {key: 'requestedRefundAmount', type: 'money', size: 'm'},
                {key: 'refundedAmount', type: 'money', size: 'm'},
                {key: 'retainedReturnFeeEuros', type: 'money', size: 'm', translationKey: 'retainedReturnFee' },
                {key: 'client', type: 'client', size: 'l'},
            ]
        }
    },
    computed: {
        ...mapState(['ordersRefunded', 'ordersRefundedFilters'])
    }
}
</script>