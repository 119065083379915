var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"table-sticky"},[_c('div',{ref:"container",staticClass:"table-sticky__container"},[_c('scroll-sync',{ref:"thead",staticClass:"table-sticky__scrollable",attrs:{"group":`${_vm._uid}`,"horizontal":"","proportional":true}},[_c('div',{staticClass:"table-sticky__header"},_vm._l((_vm.fields),function(field,i){return _c('TableHeadTh',{key:i,class:`is-size--${field.size || 's'}`,attrs:{"tag":"div","field":field,"filters":_vm.filters}})}),1)]),(_vm.data || _vm.$slots.default)?_c('scroll-sync',{staticClass:"table-sticky__scrollable",attrs:{"group":`${_vm._uid}`,"horizontal":"","proportional":true}},[(_vm.data && !_vm.data.length)?_c('div',{staticClass:"table-sticky__empty"},[_c('TextSimple',{attrs:{"size":"s"}},[_vm._v(_vm._s(_vm.$t("global.no-results-found")))])],1):_c('div',{ref:"content",staticClass:"table-sticky__body",attrs:{"data-testid":"table-sticky__body"}},[_vm._l((_vm.data),function(item,i){return _c('TableStickyRow',{key:i},_vm._l((_vm.fields),function(field,y){return _c('TableCell',{key:y,class:`is-size--${field.size || 's'}`,attrs:{"tag":"div","value":_vm._f("fieldValue")(item,field),"type":field.type,"route":field.route,"text":field.text,"param":field.param,"menu-options":field.actions,"handler":field.handler,"translation-key":field.translationKey,"item-type":field.itemType,"object":{
              ...item,
              translationKey: field.translationKey,
              itemType: field.itemType,
            }}})}),1)}),_vm._t("default")],2)]):_c('div',{staticClass:"table-sticky__loader"},[_c('SvgLoader')],1),(_vm.data?.length || _vm.$slots.default || (_vm.pagination && _vm.data))?_c('div',{staticClass:"table-sticky__bottom"},[(_vm.data?.length || _vm.$slots.default)?_c('TableStickyBar',{attrs:{"group":`${_vm._uid}`,"width":_vm.$refs.thead?.scrollWidth}}):_vm._e(),(_vm.data && _vm.pagination)?_c('TablePagination',{attrs:{"pagination":_vm.pagination,"default-value":_vm.defaultItemsPerPage,"data-testid":'pagination-bar'}}):_vm._e()],1):_vm._e(),(_vm.fields[0] && _vm.fields[0].type === 'eye')?_c('div',{staticClass:"table-sticky__fixed"},[_c('div',{staticClass:"table-sticky__header"},[_c('TableHeadTh',{class:`is-size--${_vm.fields[0].size || 'm'}`,attrs:{"tag":"div","field":_vm.fields[0],"filters":_vm.filters}})],1),(_vm.data)?_c('div',{staticClass:"table-sticky__body"},_vm._l((_vm.data),function(item,i){return _c('TableStickyRow',{key:i},[_c('TableCell',{key:i,class:`is-size--${_vm.fields[0].size || 'm'}`,attrs:{"tag":"div","value":_vm._f("fieldValue")(item,_vm.fields[0]),"type":_vm.fields[0].type,"route":_vm.fields[0].route,"handler":_vm.fields[0].handler,"menu-options":_vm.fields[0].actions,"param":_vm.fields[0].param,"translation-key":_vm.fields[0].translationKey,"item-type":_vm.fields[0].itemType,"object":{
              ...item,
              translationKey: _vm.fields[0].translationKey,
              itemType: _vm.fields[0].itemType,
            }}})],1)}),1):_vm._e()]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }