<template>
    <router-link :to="{name: 'config-website-catalogues-articles-details', params: {id: itemData.id}}" class="recom-order-card">
        <div :class="itemData.metadata?.photos?.length ? 'recom-order-card__photo' : 'recom-order-card__image'">
            <img v-if="itemData.metadata?.photos?.length" :src="itemData.metadata?.photos[0]" alt="photo">
            <SvgIcon v-else name="tshirt"/>
        </div>
        <div class="recom-order-card__text">
            <div class="recom-order-card__text-title">
                <TextTitle size="xs">{{ title }}</TextTitle>
            </div>
            <TextSimple color="grey">{{ color }} / {{ size }} / {{ $t(`global.${condition}`) }}</TextSimple>
            <TextSimple color="grey">{{ $t("global.reference") }}: {{ reference }}</TextSimple>
            <TextSimple v-if="returnItem" color="grey" size="xs">{{ $t("order.return_reason") }}: {{ $t(`order.${returnItem.reason}`) }}</TextSimple>
            <TagType translationKey="article_state"  :itemType="type" :value="item.state"/>
        </div>
        <TextSimple>{{ itemData.priceResale || null | formatPrice }}</TextSimple>
    </router-link>
</template>

<script>
import { mapState } from "vuex";
import TextTitle from "@/components/ui/text/TextTitle";
import TextSimple from "@/components/ui/text/TextSimple";
import SvgIcon from "@/components/ui/svg/SvgIcon";
import TagType from "@/components/ui/tag/TagType";

export default {
  name: "RecomOrderCardItem",
  components: { TextSimple, TextTitle, SvgIcon, TagType },
  props: {
    item: Object,
    returnItem: Object,
    type: {
            type: String,
            default: 'order',
            validator: value => ['order', 'resale'].includes(value)
        }
  },
  computed: {
    ...mapState(["clients"]),
    itemData() {
      // Si l'item a une propriété tradeIn, on utilise celle-ci
      // Sinon on utilise directement l'item
      return this.item?.tradeIn || this.item || {};
    },
    title() {
      return this.getMetadataValue("title");
    },
    color() {
      return this.getMetadataValue("color");
    },
    size() {
      return this.getMetadataValue("size");
    },
    condition() {
      return this.itemData?.condition || null;
    },
    ean() {
      return this.itemData?.ean || null;
    },
    sku() {
      return this.itemData?.sku || null;
    },
    reference() {
      return this.itemData?.reference || null;
    },
    idfaume() {
      return this.itemData?.id || null;
    },
  },
  methods: {
    getMetadataValue(key) {
      const metadata = this.itemData?.metadata;
      if (!metadata) return null;

      return (
        metadata.fr_FR?.[key] ||
        metadata.en_GB?.[key] ||
        metadata.de_DE?.[key] ||
        null
      );
    },
  },
};
</script>

<style lang="scss">
.recom-order-card {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 15px;

  & ~ .recom-order-card {
    margin-top: 15px;
  }

  .tag {
    margin-left: 0;
    margin-top: 5px;
  }

  &__image,
  &__photo {
    width: 60px;
    aspect-ratio: 0.7;
    flex-shrink: 0;
    border: var(--border-1);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &__image {
    transition: border ease 0.2s;
    --svg-color: var(--color-text-grey);
  }

  &__photo {
    transition: border ease 0.2s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover .order-card__image {
    border: var(--border-2);
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 3px;

    &-title {
      display: flex;
      align-items: flex-start;
      column-gap: 10px;
      @media screen and (max-width: 700px) {
        row-gap: 5px;
        flex-direction: column;
      }
    }
  }

  & > p {
    margin-left: auto;
  }
}
</style>
