import store from '@/store/index';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Router from 'vue-router';

// Pages
import Error403 from '@/components/pages/Error403';
import LoaderPage from '@/components/pages/Loader';
import LoginPage from '@/components/pages/Login';
import ProfileSettingsPage from '@/components/pages/admin/Profile';

// Routes
import adminsRoutes from '@/router/modules/admins';
import articlesRoutes from '@/router/modules/articles';
import cataloguesRoutes from '@/router/modules/catalogues';
import cohortsRoutes from '@/router/modules/cohorts';
import configRoutes from '@/router/modules/config';
import customersRoutes from '@/router/modules/customers';
import dashboardRoutes from '@/router/modules/dashboard';
import homeRoutes from '@/router/modules/home';
import invoicesRoute from '@/router/modules/invoices';
import merchandisingRoutes from '@/router/modules/merchandising';
import monitoringRoutes from '@/router/modules/monitoring';
import ordersRoutes from '@/router/modules/orders';
import passwordRoutes from '@/router/modules/password';
import productsRoutes from '@/router/modules/products';
import promotionsRoutes from '@/router/modules/promotions';
import resalesRoutes from '@/router/modules/resales';
import retailRoutes from '@/router/modules/retail';
import warehouseRoutes from '@/router/modules/warehouse';
import shippingsRoutes from '@/router/modules/shippings';

Vue.use(Router);


// Gérer à la fois push et replace
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
    console.log('push', location)
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            throw err;
        }
        return err;
    });
};

VueRouter.prototype.replace = function replace(location) {
    console.log('replace', location)
    return originalReplace.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            throw err;
        }
        return err;
    });
};

const router = new Router({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            name: 'home',
            component: LoginPage,
            meta: {
                public: true
            }
        },
        {
            path: '/compte',
            name: 'profile',
            component: ProfileSettingsPage
        },
        {
            path: '/loading',
            name: 'loader',
            component: LoaderPage,
            meta: {
                public: true
            }
        },
        {
            path: '/acces-refuse',
            name: 'error-403',
            component: Error403
        },
        ...passwordRoutes,
        ...warehouseRoutes,
        ...articlesRoutes,
        ...cataloguesRoutes,
        ...cohortsRoutes,
        ...productsRoutes,
        ...merchandisingRoutes,
        ...monitoringRoutes,
        ...configRoutes,
        ...promotionsRoutes,
        ...invoicesRoute,
        ...resalesRoutes,
        ...retailRoutes,
        ...customersRoutes,
        ...adminsRoutes,
        ...dashboardRoutes,
        ...ordersRoutes,
        ...shippingsRoutes,
        ...homeRoutes,
        {
            path: '*',
            redirect: '/'
        },

    ]
});

router.beforeEach(async (to, from, next) => {
    if (to.meta && to.meta.public) {
        return next()
    } else {
        if (from.name === 'password-reset') {
            return
        } else if (store.state.user && store.state.user.access_token) {
            const user = store.state[store.state.temporaryUser ? 'temporaryUser' : 'user']
            const roleAccepted = !to.meta.roles?.length ? true : user.roles?.find(role => to.meta.roles.includes(role));
            const cmsAccepted = !to.meta.cms?.length ? true : user.cms?.find(value => to.meta.cms.includes(value));
            if(roleAccepted && cmsAccepted){
                return next()
            } else {
                return next({name: 'error-403'})
            }
        } else {
            return next({name: 'home'})
        }
    }
});
router.afterEach( async () => {
    document.body.classList.remove('no-scroll');
});



export default router