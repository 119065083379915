<template>
  <section class="table-sticky">
    <div class="table-sticky__container" ref="container">
      <scroll-sync
        :group="`${_uid}`"
        horizontal
        :proportional="true"
        class="table-sticky__scrollable"
        ref="thead"
      >
        <div class="table-sticky__header">
          <TableHeadTh
            :class="`is-size--${field.size || 's'}`"
            tag="div"
            v-for="(field, i) in fields"
            :key="i"
            :field="field"
            :filters="filters"
          />
        </div>
      </scroll-sync>

      <scroll-sync
        :group="`${_uid}`"
        horizontal
        :proportional="true"
        class="table-sticky__scrollable"
        v-if="data || $slots.default"
      >
        <div class="table-sticky__empty" v-if="data && !data.length">
          <TextSimple size="s">{{ $t("global.no-results-found") }}</TextSimple>
        </div>
        <!-- Modification dans la boucle principale -->
        <div
          class="table-sticky__body"
          data-testid="table-sticky__body"
          ref="content"
          v-else
        >
          <TableStickyRow v-for="(item, i) in data" :key="i">
            <TableCell
              tag="div"
              v-for="(field, y) in fields"
              :key="y"
              :class="`is-size--${field.size || 's'}`"
              :value="item | fieldValue(field)"
              :type="field.type"
              :route="field.route"
              :text="field.text"
              :param="field.param"
              :menu-options="field.actions"
              :handler="field.handler"
              :translation-key="field.translationKey"
              :item-type="field.itemType"
              :object="{
                ...item,
                translationKey: field.translationKey,
                itemType: field.itemType,
              }"
            />
          </TableStickyRow>
          <slot />
        </div>
      </scroll-sync>

      <div v-else class="table-sticky__loader">
        <SvgLoader />
      </div>

      <div
        class="table-sticky__bottom"
        v-if="data?.length || $slots.default || (pagination && data)"
      >
        <TableStickyBar
          :group="`${_uid}`"
          :width="$refs.thead?.scrollWidth"
          v-if="data?.length || $slots.default"
        />
        <TablePagination
          v-if="data && pagination"
          :pagination="pagination"
          :default-value="defaultItemsPerPage"
          :data-testid="'pagination-bar'"
        />
      </div>

      <div
        v-if="fields[0] && fields[0].type === 'eye'"
        class="table-sticky__fixed"
      >
        <div class="table-sticky__header">
          <TableHeadTh
            :class="`is-size--${fields[0].size || 'm'}`"
            tag="div"
            :field="fields[0]"
            :filters="filters"
          />
        </div>
        <div class="table-sticky__body" v-if="data">
          <TableStickyRow v-for="(item, i) in data" :key="i">
            <TableCell
              tag="div"
              :class="`is-size--${fields[0].size || 'm'}`"
              :key="i"
              :value="item | fieldValue(fields[0])"
              :type="fields[0].type"
              :route="fields[0].route"
              :handler="fields[0].handler"
              :menu-options="fields[0].actions"
              :param="fields[0].param"
              :translation-key="fields[0].translationKey"
              :item-type="fields[0].itemType"
              :object="{
                ...item,
                translationKey: fields[0].translationKey,
                itemType: fields[0].itemType,
              }"
            />
          </TableStickyRow>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableStickyRow from "@/components/ui/table-sticky/TableStickyRow";
import TableCell from "@/components/ui/table/cell/TableCell";
import SvgLoader from "@/components/ui/svg/SvgLoader";
import TablePagination from "@/components/ui/table/TablePagination";
import TableHeadTh from "@/components/ui/table/thead/TheadTh";
import TableStickyBar from "@/components/ui/table-sticky/TableStickyBar";
import TextSimple from "@/components/ui/text/TextSimple";

export default {
  name: "TableSticky",
  components: {
    TextSimple,
    TableStickyBar,
    TableHeadTh,
    TablePagination,
    SvgLoader,
    TableCell,
    TableStickyRow,
  },
  props: {
    fields: Array,
    data: Array,
    sort: Boolean,
    pagination: Object,
    filters: Array,
    defaultItemsPerPage: Number,
  },
  watch: {
    data() {
      if (this.$refs.thead?.$el) {
        this.$refs.thead.$el.dispatchEvent(new CustomEvent("scroll"));
      }
    },
  },
};
</script>

<style lang="scss">
.table-sticky {
  margin-bottom: 30px;

  &__container {
    position: relative;
  }

  &__header > *,
  .table-sticky-row > * {
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    flex-shrink: 0;

    &:not(:first-of-type) {
      flex-grow: 1;
    }
  }

  .table-sticky-row,
  &__header {
    display: flex;
    height: 45px;
    text-align: center;
  }

  .table-sticky-row {
    font-size: 1.2rem;

    & > * > img,
    & > * > * > img {
      width: 34px;
      height: 34px;
      border-radius: 0;
    }
  }

  &__header {
    font-size: 1.1rem;
    color: var(--color-text-grey);
    font-weight: 100;
    height: 45px;
  }

  &__scrollable {
    overflow-x: auto;
    border-radius: var(--border-radius-2);
    scrollbar-width: none;
    scrollbar-height: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0 !important;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scrollbar-height: none;
    }

    &::-moz-scrollbar {
      display: none;
    }

    &:nth-child(1) {
      background-color: var(--color-bg-grey);
      position: sticky;
      top: calc(var(--filter-height) + var(--height-header));
      z-index: 1;
      margin-bottom: 4px;
    }

    &:nth-child(2) {
      background-color: #fff;

      &::-webkit-scrollbar {
        height: 8px;
        border-radius: 10px;
        overflow: hidden;
      }

      &::-webkit-scrollbar-track {
        background: var(--color-bg-grey);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb:hover {
        background: var(--color-text-grey);
        border-radius: 10px;
      }
    }
  }

  .table-cell,
  .table-head-th {
    &.is-size--xs {
      width: 50px;
    }

    &.is-size--m {
      width: 150px;
    }

    &.is-size--l {
      width: 200px;
    }

    &.is-size--xl {
      width: 250px;
    }

    &.is-size--xxl {
      width: 300px;
    }
  }

  &__loader {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
    --svg-color: #ccc;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  &__fixed {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-right: solid 1px var(--color-bg-light);

    .table-sticky__header {
      background-color: var(--color-bg-grey);
      border-radius: var(--border-radius-2);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 4px;
      position: sticky;
      top: calc(var(--filter-height) + var(--height-header));
    }

    .table-sticky__body {
      background-color: #fff;
    }
  }

  &__bottom {
    @media screen and (min-width: 1025px) {
      position: sticky;
      bottom: 0;
      z-index: 2;
      padding-bottom: 10px;
      background-color: rgba(247, 246, 242, 0.8);
      backdrop-filter: blur(0.5rem);

      .pagination {
        margin-top: 5px;
        position: relative;

        &__pages {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &__top {
          margin-bottom: 0;
        }
      }
    }
  }

  &__empty {
    padding: 15px;
    text-align: left;
    background-color: #fff;
  }

  .input-checkbox {
    border-radius: var(--border-radius-1);
    border-width: 1px;
  }

  .table-cell {
    .tag-list {
      justify-content: center;
      flex-wrap: nowrap;
    }
  }
}
</style>
