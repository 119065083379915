var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template-table",class:{ 'no-filters': !_vm.availableFilters?.length }},[_c('PageBack',{attrs:{"to":_vm.pageBackUrl}}),_c('PageHeader',{attrs:{"title":_vm.title,"subtitle":_vm.subtitle,"text":_vm.text},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions"),(!_vm.hideExport)?_c('ButtonExport',{attrs:{"disabled":_vm.exportIsDisabled,"hide":!_vm.table},nativeOn:{"click":function($event){$event.preventDefault();return _vm.exportCsv.apply(null, arguments)}}}):_vm._e()]},proxy:true}],null,true)}),_vm._t("top"),(_vm.availableFilters?.length || _vm.$slots.right)?_c('PageFilters',{scopedSlots:_vm._u([(
        _vm.availableFilters?.length &&
        (_vm.displaySearch ||
          _vm.displayDate ||
          _vm.displaySelect ||
          _vm.filtersSelected?.length)
      )?{key:"left",fn:function(){return [(_vm.displaySearch)?_c('Field',{staticClass:"template-table__search",attrs:{"type":"text","model":_vm.searchTerm,"placeholder":_vm.searchPlaceholder,"label":_vm.$t('global.search')},on:{"change":(e) => (_vm.searchTerm = e)}}):_vm._e(),(_vm.displayDate)?_c('FieldDate',{attrs:{"label":_vm.$t('global.date'),"model":_vm.date},on:{"change":(e) => (_vm.date = e)}}):_vm._e(),_c('FiltersSelected',{attrs:{"selected":_vm.filtersSelected,"filters":_vm.filters,"scope":_vm.scope},on:{"delete":(i) => _vm.$refs.filtersPopin.removeField(i)}}),(_vm.displaySelect)?_c('FieldSelect',{staticClass:"template-table__displaySelect",attrs:{"label":_vm.displaySelect?.label,"options":_vm.displaySelect.options,"selected":_vm.displaySelect?.selectedItem,"sorted":_vm.displaySelect?.sorted},on:{"input":_vm.displaySelect.updateFn}}):_vm._e()]},proxy:true}:null,{key:"right",fn:function(){return [_vm._t("right"),(_vm.availableFilters?.length)?_c('FiltersButton',{nativeOn:{"click":function($event){_vm.displayFiltersPopin = true}}}):_vm._e()]},proxy:true}],null,true)}):_vm._e(),(_vm.availableFilters && _vm.availableFilters.length)?_c('FiltersPopin',{ref:"filtersPopin",attrs:{"visible":_vm.displayFiltersPopin,"filters":_vm.availableFilters,"scope":_vm.scope},on:{"close":function($event){_vm.displayFiltersPopin = false}}}):_vm._e(),(_vm.table && !_vm.table.items)?_c('TextSimple',[_vm._v(_vm._s(_vm.$t("global.no-results-found")))]):(_vm.displayTable)?_c('TableSticky',{attrs:{"fields":_vm.availableFields,"data":_vm.table ? _vm.table.items : null,"pagination":_vm.table ? _vm.table.pagination : null,"defaultItemsPerPage":_vm.defaultItemsPerPage,"filters":_vm.availableFilters}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }