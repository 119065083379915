<template>
    <TemplateTable :title="$t('menu.recom-promo-codes')" :text="$t('recom_promocodes.description')" name="recomPromocodes" :url="$listRoutes.recomPromoCodes" :table="recomPromocodes" :filters="recomPromocodesFilters" :fields="fields">
        <template v-slot:actions>
            <ButtonLink size="m" color="grey" icon="edit" :to="{name: 'recom-promo-codes-create'}">{{ $t('recom_promocode-create.title') }}</ButtonLink>
        </template>
    </TemplateTable>
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
import ButtonLink from '@/components/ui/button/ButtonLink';
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
    name: 'recomPromocodesPage',
    mixins: [currencyRouteMixin],
    components: { TemplateTable, ButtonLink },
    data() {
        return {
            fields: [
                { key: 'id', type: 'eye', route: 'recom-promo-codes-edit', size: 'xs'},
                { key: 'brand.name' },
                { key: 'code' },
                { key: 'value', translationKey: 'value', type: 'promoCode'},
                { key: 'appliedTo', type: 'tagType', translationKey: 'eventType' },
                { key: 'createdAt', type: 'dateHourFromUTC', size: 'l' },
                { key: 'startedAt', type: 'dateHourFromUTC', size: 'l' },
                { key: 'endedAt', translationKey: 'expiresOn', type: 'dateHourFromUTC', size: 'l' },
                { key: 'state', type: 'tagType' }
            ]
        }
    },
    computed: {
        ...mapState(['recomPromocodes', 'recomPromocodesFilters']),
    }
}
</script>