<template>
    <TemplateTable
        :title="title"
        name="articles"
        :url="$listRoutes.newArticles"
        :table="articles"
        :fields="fields"
        :filters="articlesFilters"
    />
</template>
<script>
import TemplateTable from '@/components/templates/TemplateTable.vue';
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

import { mapActions, mapState } from 'vuex';

export default {
    name: 'ConfigWebsiteCatalogueArticleListingAll',
    mixins: [currencyRouteMixin],
    components: {
        TemplateTable,
    },
    data() {
        return {
            page: 1,
            limit: 10,
            loading: false,
        }
    },
    computed: {
        ...mapState([
            'articles',
            'articlesLoading',
            'articlesFilters'
        ]),
        title() {
            return this.$t('articles.all_articles_title') 
        },
        fields(){
            return [
                {key: 'id', type: 'eye', route: 'config-website-catalogues-articles-details', size: 'xs'},
                {key: 'metadata.photos.0', type: 'image', size: 'xs', translation: ' '},
                {key: 'brand', type: 'text', size: 's'},
                {key: 'reference', type: 'text', size: 's', translation: 'Référence'},
                {key: 'sku', type: 'text', size: 's'},
                {key: 'ean', type: 'text', size: 's'},
                {key: 'metadata.fr_FR.title', type: 'text', size: 'm'},
                {key: 'color', type: 'text', size: 's'},
                {key: 'size', type: 'text', size: 's'},
                {key: 'condition', type: 'tagType', size: 's'},
                {key: 'priceResale' , type: 'formatPrice', size: 's'},
                {key: 'state', type: 'articleState', size: 's', translation: 'Status'},
                {key: 'updatedAt', type: 'date', size: 's', translation: 'Modifié le'},
            ]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        ...mapActions([
            'fetchNavIds',
        ]),
        updateQuery(stateValue) {
            if(this.$route.query['state[in]'] !== stateValue) {
                this.$router.push({ 
                    path: this.$route.path, 
                    query: { 
                        ...this.$route.query, 
                        'state[in]': stateValue,
                        'page': this.$route.query.page || '1',
                        'limit': this.$route.query.limit || '10',
                    }
                })
                this.loading = true
            }
        }
    },
}
</script>
