<template>
    <TemplateTable
            :title="$t('menu.wallets')"
            :text="$t('wallets.description')"
            name="wallets"
            :url="$listRoutes.wallets"
            :table="wallets"
            :filters="walletsFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
    name: 'WalletsPage',
    mixins: [currencyRouteMixin],
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'wallet', size: 'xs'},
                //{key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'user.email', size: 'xl'},
                {key: 'user.firstname', size: 'm'},
                {key: 'user.lastname', size: 'm'},
                {key: 'client', type: 'client', size: 'l', noSortable: true},
                {key: 'walletAmount', type: 'formatPrice', noSortable: true},
                {key: 'nbCredit', noSortable: true},
                {key: 'nbDebit', noSortable: true},
                {key: 'credit', type: 'formatPrice', noSortable: true},
                {key: 'debit', type: 'formatPrice', noSortable: true},
                {key: 'source', type: 'tagType', noSortable: true},
                {key: 'country', size: 'xs', noSortable: true},
            ]
        }
    },
    computed: {
        ...mapState(['wallets', 'walletsFilters'])
    }
}
</script>