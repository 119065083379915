<template>
  <TemplateTable
    :title="$t('menu.recom-resales-stocks')"
    :text="$t('resalesStocks.description')"
    name="resalesStocks"
    :url="$listRoutes.recomResalesStock"
    :table="resalesStocks"
    :filters="resalesStocksFilters"
    :fields="fields"
    :display-date="true"
  />
</template>

<script>
import TemplateTable from "@/components/templates/TemplateTable";
import { mapState } from "vuex";
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
  name: "RecomResalesStocks",
  mixins: [currencyRouteMixin],
  components: { TemplateTable },
  data() {
    return {
      fields: [
        { key: "id", type: "eye", route: "recom_tradein-article", size: "xs" },
        { key: "brand", type: "client", size: "l" },
        { key: "reference", size: "l", translationKey: "article_id" },
        { key: "ean", size: "l" },
        { key: "state", type: "tagType", size: "m" },
        {
          key: "priceOffer",
          type: "formatPrice",
          size: "m",
          translationKey: "tradeinPrice",
        },
        {
          key: "amountCredited",
          type: "formatPrice",
          size: "lm",
          translationKey: "controlReviewPrice",
        },
        { key: "priceResale", type: "formatPrice", size: "m" },
        { key: "condition", size: "m", type: "tagType" },
        { key: "warehouseSlot", type: "boolean", translationKey: "stocked" },
        { key: "warehouseSlotPosition", type: "copy", size: "m" },
        { key: "warehouseStage", type: "tagType", size: "m" },
        { key: "createdAt", type: "date", size: "m" },
        { key: "updatedAt", type: "date", size: "m" },
        { key: "tradeInCart.customer.profile", type: "tagType" },
        { key: "tradeInCart.customer.countryCode" },
      ],
    };
  },
  computed: {
    ...mapState(["resalesStocks", "resalesStocksFilters"]),
  },
};
</script>
