<template>
    <TagList v-if="selectedFilters.length" class="filters-selected">
        <ButtonCustom 
            v-for="(filter, i) in selectedFilters"
            :key="`${filter.key}-${filter.operator}-${filter.value}-${i}`" 
            v-show="filter.operator && filter.value && filter.key"
            @click.native.prevent="$emit('delete', i)"
            size="s"
            color="grey"
            icon="cross">
            {{ getValue(filter.key, filter.value, filter.operator) }}
        </ButtonCustom>
    </TagList>
</template>

<script>
import TagList from '@/components/ui/tag/TagList';
import ButtonCustom from '@/components/ui/button/Button';

export default {
    name: 'FiltersSelected',
    components: { ButtonCustom, TagList },
    props: {
        selected: Array,
        filters: Array,
        scope: {
            type: String,
            default: 'global'
        }
    },
    computed: {
        selectedFilters() {
            return this.selected?.filter(f => f.key !== 'currency') || [];
        }
    },
    methods: {
        getValue(key, value, operator) {
            if (!value || !key) return value;
            
            const filter = this.filters.find(f => f.key == key);
            if (filter) {
                if (filter.type === 'boolean') {
                    return this.$t(`global.${value == '1' ? 'yes' : 'no'}`);
                }
                
                if (filter.type === 'select') {
                    if (Array.isArray(value)) {
                        return value.map(v => {
                            const option = filter.options.find(o => o.id == v);
                            if (option) {
                                let translationKey = this.$te(`${this.scope}.${option.label}`) ? 
                                    `${this.scope}.${option.label}` : 
                                    `global.${option.label}`;
                                return this.$te(translationKey) ? this.$t(translationKey) : option.label;
                            }
                            return value;
                        }).join(', ');
                    } else {
                        const option = filter.options.find(o => o.id == value);
                        if (option) {
                            let translationKey = this.$te(`${this.scope}.${option.label}`) ? 
                                `${this.scope}.${option.label}` : 
                                `global.${option.label}`;
                            return this.$te(translationKey) ? this.$t(translationKey) : option.label;
                        }
                        return value;
                    }
                }
            }
            
            if (value.includes(':00')) {
                return `${operator === 'lte' ? '< ' : operator === 'gte' ? '> ' : ''}${this.$options.filters.momentDateTime(value)}`;
            }
            
            return Array.isArray(value) ? value.join(', ') : value;
        }
    },
    watch: {
        selected: {
            handler() {
                this.$forceUpdate();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.filters-selected {
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
</style>