<template>
  <TemplateDetails
    :data="catalogueCollections"
    :loading="catalogueCollectionsLoading"
    :get-function="getDatas"
  >
    <div class="catalogue-collections">
      <PageBack />
      <PageHeader :title="title">
        <template v-slot:actions>
          <FieldSelect
            v-if="isAdmin && isRouteIdIsUndefined"
            :options="optionsForClientsSelectField"
            :selected="computedClientID"
            @input="clientID = $event"
          />
          <ButtonLink
            :to="linkToCollections"
            icon="right"
            color="grey"
            size="m"
            >{{ $t("collections.collections_create") }}</ButtonLink
          >
        </template>
      </PageHeader>
      <div v-if="catalogueCollections && catalogueCollections.length > 0">
        <CollectionsListing
          v-for="(collection, index) in catalogueCollections"
          :key="index"
          :index="index"
          :collection="collection"
          :defaultLocale="defaultLocale"
          :brandID="computedClientID"
        />
      </div>
      <div v-else>
        <TextSimple weight="bold" size="l">{{
          $t("collections.no_collections")
        }}</TextSimple>
      </div>
    </div>
  </TemplateDetails>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import TemplateDetails from "@/components/templates/TemplateDetails.vue";
import PageBack from "@/components/ui/page/PageBack.vue";
import PageHeader from "@/components/ui/page/PageHeader.vue";
import ButtonLink from "@/components/ui/button/ButtonLink.vue";
import CollectionsListing from "@/components/ui/collections/CollectionsListing.vue";
import TextSimple from "@/components/ui/text/TextSimple.vue";
import FieldSelect from "@/components/ui/form/fields/FieldSelect.vue";
export default {
  name: "CatalogueCollections",
  components: {
    TemplateDetails,
    PageBack,
    PageHeader,
    ButtonLink,
    CollectionsListing,
    TextSimple,
    FieldSelect,
  },
  watch: {
    clientID(newValue) {
      if (newValue) {
        this.loadClientData(newValue);
      }
    },
    currentCurrency: {
      async handler() {
        if (this.initialLoadDone) {
          // Réinitialiser la sélection du client si nécessaire
          await this.handleCurrencyChange();
        }
      },
    },
    economicZoneClients: {
      async handler(newClients) {
        if (this.initialLoadDone && newClients?.length) {
          await this.handleEconomicZoneChange();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      clientID: null,
      initialLoadDone: false,
    };
  },
  computed: {
    ...mapState([
      "catalogueCollection",
      "catalogueCollections",
      "catalogueCollectionsLoading",
      "catalogueDefaultLocale",
      "collectionsClients",
      "user",
      "temporaryUser",
    ]),
    ...mapGetters(["isAdmin"]),
    ...mapState({
      economicZoneClients: (state) => state.currency.economicZoneClients,
      currentCurrency: (state) => state.currency.currency,
    }),
    allowedClients() {
      let filteredClients = this.collectionsClients || [];

      if (this.economicZoneClients?.length) {
        const economicZoneClientIds = this.economicZoneClients.map((c) => c.id);
        filteredClients = filteredClients.filter((client) =>
          economicZoneClientIds.includes(client.id)
        );
      }

      return filteredClients;
    },
    id() {
      return (
        this.$route.params.brandID ||
        this.user?.clients?.[0]?.id ||
        this.temporaryUser?.clients?.[0]?.id ||
        1
      );
    },
    isRouteIdIsUndefined() {
      return typeof this.$route?.params?.id === "undefined";
    },
    computedClientID() {
      const currentId = this.clientID || this.id;

      if (this.economicZoneClients?.length) {
        const isInZone = this.economicZoneClients.some(
          (c) => c.id === parseInt(currentId)
        );
        return isInZone ? currentId : this.economicZoneClients[0]?.id;
      }

      return currentId;
    },
    client() {
      return this.$store.getters.getClientById(this.computedClientID);
    },
    title() {
      return `${this.$t("collections.collections_list")} ${this.client.name}`;
    },
    defaultLocale() {
      return this.catalogueDefaultLocale?.defautLocale;
    },
    optionsForClientsSelectField() {
      return this.allowedClients?.length
        ? this.allowedClients
            .map((client) => ({
              id: client.id,
              label: client.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [];
    },
    linkToCollections() {
      return {
        name: "config-website-catalogue-collections-create",
        params: { lang: this.defaultLocale, id: this.computedClientID },
      };
    },
  },
  methods: {
    ...mapActions([
      "catalogueGetAllCollections",
      "getCatalogueDefaultLocale",
      "catalogueCollectionRemoveCondition",
      "fetchCollectionsClients",
    ]),

    async loadClientData(clientId) {
      try {
        await Promise.all([
          this.catalogueGetAllCollections(clientId),
          this.getCatalogueDefaultLocale(clientId),
        ]);

        this.$router.push({
          name: "config-website-catalogues-collections-listing",
          params: { brandID: clientId },
        });
      } catch (error) {
        console.error("Erreur lors du chargement des données client:", error);
      }
    },

    async handleCurrencyChange() {
      // Si le client actuel n'est pas dans la nouvelle zone économique
      const currentClientId = parseInt(this.computedClientID);
      const isClientInNewZone = this.economicZoneClients.some(
        (c) => c.id === currentClientId
      );

      if (!isClientInNewZone && this.economicZoneClients.length) {
        // Sélectionner le premier client de la nouvelle zone
        this.clientID = this.economicZoneClients[0].id;
        await this.loadClientData(this.clientID);
      }
    },

    async handleEconomicZoneChange() {
      const currentClientId = parseInt(this.computedClientID);
      const isClientInZone = this.economicZoneClients.some(
        (c) => c.id === currentClientId
      );

      if (!isClientInZone && this.economicZoneClients.length) {
        // Mettre à jour avec le premier client de la nouvelle zone
        this.clientID = this.economicZoneClients[0].id;
        await this.loadClientData(this.clientID);
      } else if (isClientInZone) {
        // Recharger les données pour le client actuel
        await this.loadClientData(currentClientId);
      }
    },
    async getDatas() {
      try {
        // D'abord vérifier la zone économique et ajuster le clientID si nécessaire
        if (this.economicZoneClients?.length) {
          const isInZone = this.economicZoneClients.some(
            (c) => c.id === parseInt(this.id)
          );
          if (!isInZone) {
            // Mettre à jour le clientID avant de charger les données
            this.clientID = this.economicZoneClients[0].id;
            // Utiliser le nouveau clientID pour charger les données
            await Promise.all([
              this.getCatalogueDefaultLocale(this.economicZoneClients[0].id),
              this.catalogueGetAllCollections(this.economicZoneClients[0].id),
              this.isRouteIdIsUndefined &&
                this.isAdmin &&
                this.fetchCollectionsClients(),
            ]);
          } else {
            // Si le client est dans la zone, charger normalement
            await Promise.all([
              this.getCatalogueDefaultLocale(this.id),
              this.catalogueGetAllCollections(this.id),
              this.isRouteIdIsUndefined &&
                this.isAdmin &&
                this.fetchCollectionsClients(),
            ]);
          }
        } else {
          // Si pas de clients de zone économique, charger normalement
          await Promise.all([
            this.getCatalogueDefaultLocale(this.id),
            this.catalogueGetAllCollections(this.id),
            this.isRouteIdIsUndefined &&
              this.isAdmin &&
              this.fetchCollectionsClients(),
          ]);
        }

        this.initialLoadDone = true;
      } catch (error) {
        console.error("Erreur lors du chargement initial:", error);
      }
    },
  },
  async created() {
    // Attendre que les données de la zone économique soient chargées avant de continuer
    if (!this.economicZoneClients?.length) {
      await this.$store.dispatch(
        "fetchEconomicZoneClients",
        this.currentCurrency
      );
    }

    // Ensuite vérifier et ajuster le client si nécessaire
    if (this.economicZoneClients?.length) {
      const initialClientId = parseInt(this.id);
      const isInZone = this.economicZoneClients.some(
        (c) => c.id === initialClientId
      );
      if (!isInZone) {
        this.clientID = this.economicZoneClients[0].id;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.catalogue-collections {
  .field-select {
    margin-top: 0;
  }
}
</style>
>
