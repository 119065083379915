// store/currency.js
import API from "@/api/api-admin";

const state = {
  currency: null,
  economicZoneClients: [],
  loadingEconomicZoneClients: false,
  economicZoneClientsError: null,
};

const getters = {
  currentCurrency: (state) => state.currency,
  getEconomicZoneClients: (state) => state.economicZoneClients,
};

const mutations = {
    SET_CURRENCY(state, currency) {
        state.currency = currency;
        localStorage.setItem('userCurrency', currency);
    },
  SET_ECONOMIC_ZONE_CLIENTS(state, clients) {
    state.economicZoneClients = clients;
  },
  SET_ECONOMIC_ZONE_CLIENTS_LOADING(state, status) {
    state.loadingEconomicZoneClients = status;
  },
  SET_ECONOMIC_ZONE_CLIENTS_ERROR(state, error) {
    state.economicZoneClientsError = error;
  },
};

const actions = {
   
    
    async updateCurrency({ commit, dispatch }, newCurrency) {
        if (!newCurrency) {
            console.error('Tentative de mise à jour avec une devise invalide');
            return;
        }
        commit('SET_CURRENCY', newCurrency);
        await dispatch('fetchEconomicZoneClients', newCurrency);
    },
    
    async initCurrency({ dispatch, rootState }) {
        const activeUser = rootState.user.temporaryUser || rootState.user.user;
        const userCurrency = activeUser?.currency;

        if (userCurrency) {
            await dispatch('updateCurrency', userCurrency);
        }
    },

  async fetchEconomicZoneClients({ commit }, currency) {
    try {
      commit("SET_ECONOMIC_ZONE_CLIENTS_LOADING", true);
      const response = await API.get(
        `/economic-zone/${currency.toLowerCase()}/clients?=`
      );
      commit("SET_ECONOMIC_ZONE_CLIENTS", response.data);
    } catch (error) {
      commit("SET_ECONOMIC_ZONE_CLIENTS_ERROR", error.message);
      commit("SET_ECONOMIC_ZONE_CLIENTS", []);
    } finally {
      commit("SET_ECONOMIC_ZONE_CLIENTS_LOADING", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
