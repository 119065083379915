<template>
    <TemplateDetails :get-function="getRecomResale" :data="resale" :loading="resaleLoading">

        <PageBack/>

        <div v-if="resale">
            <PageHeader :title="$t('recom_resale.title') + resale.reference">
                <template v-slot:text>
                    <TextSimple color="grey">{{ resale.createdAt | dateHour }}</TextSimple>
                    <TagList>
                        <TagType :value="resale.state"/>
                        <TagType v-if="resale.logisticianCondition" :value="resale.logisticianCondition"/>
                    </TagList>
                </template>
                <template v-slot:actions>
                    <ButtonLink v-if="resale.transfertVoucher" :to="resale.transfertVoucher" icon="download" size="m" color="grey">{{ $t("resale.print-voucher") }}</ButtonLink>
                </template>
            </PageHeader>
            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('resale.informations')"/>
                        <SectionTableInfos :object="resale" :fields="informationsFields"/>
                    </GridContent>
                </GridCard>
                <ResaleItemsGrid :resale="resale" />
                <template v-slot:aside>
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.customer')" size="xs"/>
                            <SectionTableInfos :object="resale" :fields="customerFields"/>
                        </GridContent>
                    </GridCard>

                    <GridContent>
                        <GridHeader :title="$t('resale.calendar')"/>
                        <GridCalendar>
                            <GridCalendarDate
                                    v-for="item in resale.activities"
                                    :key="item.event"
                                    :title="$t(`recom_resale.states.${item.event}.title`)"
                                    :set="infosKey = `recom_resale.states.${item.event}.text`"
                                    :comment="$t(infosKey) !== infosKey ? $t(infosKey) : null"
                                    :date="item.createdAt"
                            />
                        </GridCalendar>
                    </GridContent>
                </template>
            </GridContainer>
        </div>
        
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PageHeader from "@/components/ui/page/PageHeader";
import TemplateDetails from "@/components/templates/TemplateDetails";
import PageBack from "@/components/ui/page/PageBack";
import GridContainer from "@/components/ui/grid/GridContainer";
import GridCard from "@/components/ui/grid/GridCard";
import GridHeader from "@/components/ui/grid/GridHeader";
import GridContent from "@/components/ui/grid/GridContent";
import TextSimple from "@/components/ui/text/TextSimple";
import GridCalendar from "@/components/ui/grid/GridCalendar";
import TagType from "@/components/ui/tag/TagType";
import ButtonLink from "@/components/ui/button/ButtonLink";
import TagList from "@/components/ui/tag/TagList";
import SectionTableInfos from "@/components/sections/SectionTableInfos";
import ResaleItemsGrid from '@/components/ui/resale/ResaleItemsGrid';
import GridCalendarDate from '@/components/ui/grid/GridCalendarDate';

export default {
    name: "ResalePage",
    components: {GridCalendarDate,ResaleItemsGrid, SectionTableInfos, TagList, ButtonLink, GridCalendar, TextSimple, GridContent, GridHeader, GridCard, GridContainer, TagType, PageBack, TemplateDetails, PageHeader},
    data() {
        return {
            informationsFields: [
                {key: 'reference', translationKey: 'id' },
                {key: 'state', type: 'tagType' },
                {key: 'itemsProposedAmount', translationKey: 'purposed_price', type: 'formatPrice', force: true },
                {key: 'itemsCreditedAmount', type: 'formatPrice', force: true },
                {key: 'itemsNb' },
                {key: 'source', type: 'tagType'},
                {key: 'channel', type: 'tagType' },
                {key: 'shippingAddress.countryCode' },
                {key: 'shipment.trackingNumber' },
                {key: 'shipment.trackingExternalLink', type: 'url'},
                {key: 'shipment.carrier.name', translationKey: 'shipmentCarrierName' },
                {key: 'store.name', translationKey: 'store' },
            ],
            customerFields: [
                {key: "brand.name", translationKey: 'client'},
                {key: 'customer.firstName'},
                {key: 'customer.lastName'},
                {key: 'customer.id', type: 'CmsCustomer', size: 'xl', translationKey: "email"},
                {key: 'customer.countryCode', type: 'country'},
                {key: 'customer.profile', type: 'tagType'},
                {key: 'customer.isGuest', type: 'boolean', force: true},
                {key: 'trackingNumber', type: 'copy'},
                {key: 'trackingExternalLink', type: 'url'},
            ],
        };
    },
    computed: {
        ...mapState(['resale', 'resaleLoading', 'clients']),
        ...mapGetters(['roles']),
    },
    methods: {
        ...mapActions(["getRecomResale", 'removeUserFromResale']),
    },
};
</script>
