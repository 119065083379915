<template>
    <div
            class="collapse"
            :class="{ 'not-ready': !maxContentHeight }"
    >
        <div class="collapse__heading" @click.prevent="toggleCollapse">
            <TextTitle :size="titleSize">{{ title }}</TextTitle>
            <SvgIcon :key="isOpen" :name="isOpen ? 'minus' : 'plus'"/>
        </div>
        <div class="collapse__content" :style="{ maxHeight: isOpen ? maxContentHeight + 'px' : '0' }">
            <div ref="textContent" class="collapse__text">
                <slot>
                    <div v-html="content"></div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import SvgIcon from '@/components/ui/svg/SvgIcon';

export default {
    name: "CollapseItem",
    components: {TextTitle, SvgIcon},
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            default: ""
        },
        titleSize: {
            type: String,
            default: "xs"
        },
        open: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: this.open,
            maxContentHeight: 0
        };
    },
    methods: {
        toggleCollapse() {
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                this.maxContentHeight = this.calculateMaxHeight();
            }
        },
        calculateMaxHeight() {
            return this.$refs.textContent ? this.$refs.textContent.offsetHeight : 0;
        },
        handleResize() {
            if (this.isOpen) {
                this.maxContentHeight = this.calculateMaxHeight();
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.maxContentHeight = this.calculateMaxHeight();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    }
};
</script>

<style scoped lang="scss">
.collapse {
    --padding: 15px;
    text-align: left;
    $self: &;
    transition: opacity ease 1s;
    font-size: 1.2rem;

    &.not-ready {
        opacity: 0;
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: center;
        padding: var(--padding) 0;
        cursor: pointer;
        border-bottom: var(--border-1);
        user-select: none;

        svg {
            width: 15px;
            height: auto;
        }
    }

    &__content {
        overflow: hidden;
        transition: max-height ease 0.3s;
    }

    &__text {
        padding: var(--padding) 0;
        display: block;

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }

        strong, em, b {
            font-weight: 600;
        }

        i {
            font-style: italic;
        }

        a {
            text-decoration: underline;
        }
    }
}
</style>