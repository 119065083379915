var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-performances"},[_c('PageHeader',{attrs:{"title":_vm.title,"subtitle":_vm.subtitle}}),(_vm.clientIsRequired && _vm.availableClients.length)?_c('FieldSelect',{key:_vm.client,attrs:{"selected":_vm.client,"options":_vm.clientsOptions,"label":_vm.$t('global.client')},on:{"input":(e) => (_vm.client = e)}}):_c('FieldSearchSelect',{attrs:{"model":_vm.selectedClients,"options":_vm.clientsOptions,"label":_vm.$t('global.clients'),"disabled":_vm.clientsOptions[0]?.disabled},on:{"change":(e) => (_vm.selectedClients = e)}}),_c('PageFilters',{scopedSlots:_vm._u([{key:"left",fn:function(){return [(true)?_c('FieldSearchSelect',{attrs:{"model":_vm.selectedCountries,"options":_vm.clientCountries,"label":_vm.$t('global.country'),"disabled":_vm.clientCountries[0]?.value === 'NO_COUNTRIES'},on:{"change":(e) => (_vm.selectedCountries = e)}}):_vm._e(),_c('FieldPeriod',{attrs:{"start":_vm.period1Start,"end":_vm.period1End,"label":_vm.$t('goldenmetrics.period1'),"disabled-today":true},on:{"change":(e) => {
            _vm.period1Start = e.start;
            _vm.period1End = e.end;
            _vm.updatePeriod2(e.comparaison);
            _vm.getData();
          }}}),(_vm.comparaison)?_c('FieldPeriod',{attrs:{"start":_vm.period2Start,"end":_vm.period2End,"label":_vm.$t('goldenmetrics.compare'),"disabled-today":true,"hide-suggestion":true,"text":_vm.comparaisonType === 'period' ? null : _vm.$t('global.none')},on:{"change":(e) => {
            _vm.period2Start = e.start;
            _vm.period2End = e.end;
            _vm.getData();
          }}},[_c('li',{class:{ 'is-active': _vm.comparaisonType === 'period' },on:{"click":function($event){$event.preventDefault();_vm.comparaisonType = 'period'}}},[_vm._v(" "+_vm._s(_vm.$t("global.custom"))+" ")]),_c('li',{class:{ 'is-active': _vm.comparaisonType === 'none' },on:{"click":function($event){$event.preventDefault();_vm.comparaisonType = 'none'}}},[_vm._v(" "+_vm._s(_vm.$t("global.none"))+" ")])]):_vm._e()]},proxy:true},(_vm.dashboardFilters)?{key:"right",fn:function(){return [_c('FiltersButton',{nativeOn:{"click":function($event){$event.preventDefault();_vm.openFilters = true}}})]},proxy:true}:null],null,true)}),(_vm.loading)?_c('PageLoader'):(_vm.dashboardData)?_c('CheckerboardContainer',_vm._l((_vm.dashboardData),function(item,key,i){return _c('CheckerboardItem',{key:i,attrs:{"title":_vm._f("goldenMetrics")((item.unit === '€' && item.value ? item.value / 100 : item.value),item.unit),"subtitle":_vm.$t(`dashboard.${key}.title`),"info":_vm.$t(`dashboard.${key}.description`),"image":_vm.dashboardIcons[key]
          ? `/img/goldenmetrics/${_vm.dashboardIcons[key]}.svg`
          : null,"statistic":_vm.comparaisonType === 'period' ? item.comparePercent : null}})}),1):_vm._e(),_c('FiltersPopin',{ref:"filtersPopin",attrs:{"visible":_vm.openFilters,"filters":_vm.dashboardFilters,"no-translate":true},on:{"close":function($event){_vm.openFilters = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }