<template>
  <TemplateTable
    :title="$t('menu.resales-credited')"
    :text="$t('resalesCredited.description')"
    name="resalesCredited"
    :url="$listRoutes.resalesCredited"
    :table="resalesCredited"
    :filters="resalesCreditedFilters"
    :fields="fields"
  />
</template>

<script>
import { mapState } from "vuex";
import TemplateTable from "@/components/templates/TemplateTable";
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
  name: "ResalesCredited",
  mixins: [currencyRouteMixin],
  components: { TemplateTable },
  data() {
    return {
      fields: [
        { key: "id", type: "eye", route: "resale", size: "xs" },
        { key: "client", type: "client", size: "l", noSortable: true },
        { key: "user", type: "userEmail", size: "xl" },
        { key: "user.type", type: "tagType", translationKey: "source" },
        { key: "shopifyId", type: "shopifyProduct", size: "m" },
        {
          key: "creditReview.createdAt",
          translationKey: "creditedAt",
          type: "date",
        },
        {
          key: "creditReview.maxTradeinPrice",
          translationKey: "maxTradeinPrice",
          type: "formatPrice",
        },
        // {key: 'logisticsInformations.carrierSendingDate', type: 'date'},
        // {key: 'logisticsInformations.carrierDeliveryDate', type: 'date'},
        { key: "logisticsInformations.warehouseReceiveDate", type: "date" },
        { key: "logisticsInformations.warehouseOpenDate", type: "date" },
        { key: "logisticsInformations.warehouseValidationDate", type: "date" },
        { key: "logisticsInformations.warehouseShootingDate", type: "date" },
        { key: "shopifyPublishedDate", type: "date" },
        { key: "shopifyPublished", type: "boolean" },
        { key: "inStock", type: "boolean" },
        { key: "irl", type: "boolean", size: "m" },
        //{key: 'creditReview.condition.name', translationKey: 'condition', type: 'tagType', noSortable: true},
        //{key: 'lastReview.condition.name', translationKey: 'lastReviewCondition', type: 'tagType', size: 'm', noSortable: true},
        { key: "country", size: "xs", noSortable: true },
      ],
    };
  },
  computed: {
    ...mapState(["resalesCredited", "resalesCreditedFilters"]),
  },
};
</script>
