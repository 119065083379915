<template>
    <section class="page-clients">
        <PageHeader :title="$t('menu.configuration-websites')">
            <template v-slot:actions v-if="allowedClients?.length > 1">
                <Field :label="$t('global.search')" :model="search" @change="e => search = e" icon="search"/>
                <FieldSelect :selected="type" @input="e => type = e" :label="$t('global.type')" :options="[{id: 1, label: $t('global.all')}, {id: 2, label: 'Shopify'}, {id: 3,label: 'CMS Faume'}]"/>
            </template>
        </PageHeader>
        <PageLoader v-if="!clients"/>
        <div v-else>
            <Checkerboard>
                <CheckerboardItem v-for="client in filteredClients" :key="client.id">
                    <TextTitle size="s">{{ client.name }} ({{  client.id }})</TextTitle>
                    <LinkUnderline :to="client.frontUrl">{{ client?.frontUrl.replace('https://', '').replace('www', '') }}</LinkUnderline>
                    <ButtonLink size="s" icon="right" color="grey" :to="{name: 'client', params: { id: client.id }}">{{ $t('global.see') }}</ButtonLink>
                </CheckerboardItem>
            </Checkerboard>
        </div>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import PageLoader from '@/components/ui/page/PageLoader';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import TextTitle from '@/components/ui/text/TextTitle';
import ButtonLink from '@/components/ui/button/ButtonLink';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard';
import Field from '@/components/ui/form/fields/Field';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';

export default {
    name: 'ClientsPage',
    components: {Checkerboard, ButtonLink, TextTitle, LinkUnderline, CheckerboardItem, PageLoader, PageHeader, Field, FieldSelect},
    data() {
        return {
            search: '',
            type: 1
        }
    },
    computed: {
        ...mapState(['clients']),
        ...mapState({
            economicZoneClients: state => state.currency.economicZoneClients,
            currentCurrency: state => state.currency.currency
        }),
        ...mapGetters(['userClients']),
        allowedClients() {
            let filteredClients = this.clients;
            
            // Filtrer d'abord par les clients autorisés pour l'utilisateur
            if (this.userClients?.length) {
                filteredClients = filteredClients.filter(client => 
                    this.userClients.find(x => x.id === client.id)
                );
            }

            // Ensuite, filtrer par la zone économique si des clients sont définis
            if (this.economicZoneClients?.length) {
                const economicZoneClientIds = this.economicZoneClients.map(c => c.id);
                filteredClients = filteredClients.filter(client => 
                    economicZoneClientIds.includes(client.id)
                );
            }

            return [...filteredClients].sort((a,b) => 
                a.id > b.id ? 1 : a.id < b.id ? -1 : 0
            );
        },
        filteredClients() {
            if (!this.allowedClients?.length) return [];
            const q = this.search.toLowerCase().trim();
            let results = [...this.allowedClients];
            if (q?.length) results = results.filter(x => x.name.toLowerCase().includes(q) || x.id == q);
            if (this.type === 2) results = results.filter(x => x.shopifyUrl?.length);
            if (this.type === 3) results = results.filter(x => !x.shopifyUrl?.length);
            return results;
        }
    },
    beforeMount() {
        if (this.userClients?.length === 1) this.$router.push({name: 'client', params: {id: this.userClients[0].id}})
    }
}
</script>

<style lang="scss">
.page-clients {
    .field-select {
        width: fit-content;
    }
}
</style>