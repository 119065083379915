const listRoutes = {
    resales: 'articles',
    resale: 'articles/:id',
    resalesPhotos: 'article-photos',
    resalesWaitingPublication: 'articles/waiting/publication',
    resalesWaitingReview: 'articles/waiting/treatment',
    resalesWaitingShooting: 'articles/waiting/shooting',
    resalesShipping: 'logistics/shippings',
    resalesBookings: 'bookings',
    resaleRemoveUser: 'articles/:id/cancel-tradein',
    admins: 'admins',
    admin: 'admins/:id',
    translations: 'translations',
    translation: 'translations/:id',
    emailTranslation: 'clients/:client/email-translations/:id',
    stocks: 'stocks',
    invoices: 'finance/invoices',
    invoice: 'finance/invoices/:id',
    invoicePdf: 'finance/invoices/:id/pdf',
    ordersArticles: 'finance/sales',
    orders: 'finance/sales-by-order',
    ordersReturns: 'finance/returns',
    ordersReturn: 'finance/returns/:id',
    ordersReturned: 'finance/returns-by-order',
    ordersRefunded: 'orders-refunds',
    orderRefunded: 'orders-refunds/:id',
    resalesStocks: 'finance/stocks',
    promotionsCredits: 'finance/credits',
    promotionsDebits: 'finance/debits',
    resalesCredited: 'finance/stock-credits',
    invoicesFolders: 'finance/invoice-folders',
    invoicesFolderPdf: 'finance/invoice-folders/:id/pdf',
    invoicesFolder: 'finance/invoice-folders/:id',
    invoicesFolderRegenerate: 'finance/invoice-folders/:id/regen',
    invoicesLineEdition: 'finance/invoice-details/:id',
    invoiceLineAdd: '/finance/invoices/:id/add-invoice-details',
    invoiceParameterEdit: '/finance/invoice-parameters/:id',
    //ordersReturnsPerOrder: 'finance/returns-per-order',
    //orders: 'orders',
    order: 'orders/:id',
    returns: 'returns',
    returnsTreated: 'returns/treated',
    return: 'returns/:id',
    clients: 'clients',
    clientMarkets: 'clients/:id/markets',
    clientLanguages: 'clients/:id/locales',
    clientLanguagesDelete: 'clients/:id/locales/:localecode',
    stores: 'clients/:id/stores',
    store: 'clients/:id/stores/:storeId',
    storeTransfer: 'retail/brands/:id/stores/:storeId/transferts/:transferId',
    storeTransferArticles: 'retail/brands/:id/stores/:storeId/transferts/:transferId/articles',
    storeTransfers: 'retail/brands/:id/stores/:storeId/transferts',
    transfersTimeline: 'retail/transferts/timeline',
    customers: 'customers',
    customer: 'customers/:id',
    wallets: 'wallets',
    wallet: 'wallets/:id',
    walletAdd: 'wallets/:id/add_line',
    walletExport: 'wallets/:id/export',
    login: 'login',
    temporaryUser: 'connectas/:id',
    vouchers: 'discount-codes',
    voucher: 'discount-codes/:id',
    voucherRefund: 'discount-codes/:id/refund',
    voucherRetry: 'discount-codes/:id/retry',
    voucherEdit: 'discount-codes/:id/set-code',
    warehouseStats: 'warehouse/dashboard',
    warehouseHours: 'warehouse/dashboard/operators/:id',
    warehouseStocks: 'warehouse/stocks/:stage',
    warehouseArticles: 'warehouse/articles',
    warehouseArticlesFilters: 'warehouse/articles/filters',
    monitoringTests: 'deployments',
    monitoringTest: 'deployments/:id',
    monitoringDatas: 'check/report',
    goldenmetricsClients: 'clients',
    dashboardGoldenmetrics: 'dashboard/goldenmetrics',
    dashboardOrders: 'dashboard/sales',
    dashboardResales: 'dashboard/tradein',
    dashboardFinances: 'dashboard/finances',
    dashboardPerformances: 'performance/:id',
    dashboardPerformancesFilters: 'performance/filters/:id',
    dashboardSellers: 'user',
    dashboardSellersFilters: 'user/filters',
    dashboardBuyers: 'customer',
    dashboardBuyersFilters: 'customer/filters',
    dashboardsFunnel: 'matomo/:id?events=:events&startDate=:startDate&endDate=:endDate',
    dashboardOperations: 'operations',
    dashboardOperationsFilters: 'operations/filters',
    passwordForgot: 'reset-password',
    passwordReset: 'reset-password/reset/:id',
    websiteTranslations: 'clients/:id/translations',
    websiteTranslationsEdit: 'clients/:client/translations/:id',
    websiteTranslationsImport: 'clients/:id/translations/import',
    websiteTranslationsExport: 'clients/:id/translations/export',
    websiteEditEmails: 'clients/:id/email-translations',
    websiteEditEmailsPublish: 'clients/:id/email-translations/publish',
    websiteEmails: '/clients/mailbuilder/:id/:locale/notifications',
    mailbuilderEmailsPreview: '/clients/mailbuilder/:id/:locale/notifications/:mailKey',
    mailbuilderEmailContent: '/clients/mailbuilder/:id/:locale/translations/:key',
    mailbuilderEmailGraphicsParameters: '/clients/mailbuilder/:id/parameters/:key',
    mailbuilderEmailsGraphicsParameters: '/clients/mailbuilder/:id/parameters',
    mailbuilderEmailsBanner: '/clients/mailbuilder/:id/template-banner',
    mailbuilderEmailsImages: '/clients/mailbuilder/:id/upload-img',
    mailbuilderEmailsPublish: '/clients/mailbuilder/:id/:locale/translations/publish',
    websiteEmailsSendTest: '/clients/mailbuilder/:id/:locale/notifications/:mailKey/send',
    websiteTranslationsPublish: 'clients/:id/translations/publish',
    countries: 'dashboard/countries',
    warehouses: 'warehouses',
    websiteMarkets: 'markets?brand[eq]=:id',
    websiteMarketsAll: 'markets',
    websiteMarketsUpdate: 'markets/:id',
    websiteMarketSaleCountries:'allowed/sale_countries',
    websiteMarketOrderCountries:'markets/:id/available-countries',
    websiteMarketCurrencies:'allowed/currencies',
    websiteMarketLocales:'allowed/locales',
    clientAvailableProducts: 'retail/brands/:id/articles',
    clientSelectedProducts: 'retail/brands/:id/articles',
    retailAvailableProducts: 'retail/my-main-store/articles',
    retailReturnProducts: '/retail/my-main-store/transferts',
    retailTransfers: 'retail/my-main-store/transferts/return',
    retailTransfer: 'retail/my-main-store/transferts/:transferId',
    retailTransferArticles: 'retail/my-main-store/transferts/:transferId/articles',
    roles: 'roles',
    // CATALOGUE
    cataloguesGroupByLang: 'client/:id/catalog/group-by-lang',
    catalogueCreate: 'catalog',
    catalogueEdit:'catalog/:id',
    catalogueDelete:'catalog/:id',
    catalogueByID: 'catalog/:id',
    cataloguePrefetch: 'prefetch/catalog',
    catalogueConfiguration: 'catalog/:catalogID/configure',
    catalogueValidation: 'launch/validation/:id',
    catalogueInvalidLines: 'find-invalid-lines/:id',
    catalogueGeneration: 'generate/catalog/:id',
    catalogueLaunch: 'client/:id/catalog/:catalogueID/active',
    catalogueUpdate: 'catalog/:catalogueID/update',
    catalogueSample: 'catalog/:catalogueID/sample',
    catalogueSearchByLangAndID: ':lang/search/brands/:id',
    catalogueLocales: 'clients/:id',
    catalogueDefaultLocale: 'client/:id',
    catalogueGetAllFields: 'client/:id/article-field',
    catalogueGetSchema: ':lang/client/:id/article-field-schema',
    catalogueGetSchemaForMapping: ':lang/client/:id/article-field-schema-mapping',
    cataloguePostField: 'client/:id/article-field',
    catalogueDeleteField: 'client/:id/article-field/:fieldID',
    catalogueDownload: ':lang/brands/:id/export/catalog',
    catalogueFieldListValue: ':lang/brands/:id/field-list-value/:field',
    // PRICING
    brandPricingDownload: 'brand/:id/export/pricing/state',
    cohorts:'catalog/:id/cohort',
    // cohortsImport: 'import/cohort',
    pricingImport: '/brand/:brandID/pricing/state',
    cohortsDownloadTemplateFile: '/pricing/state/:extension/base ',
    //MERCHANDISING
    merchandisingDefaultLang:'/client/:id',
    merchandisingCriterias: '/client/:id/criterias',
    merchandisingCategories: '/tag-list/brands/:id',
    merchandisingPut: '/client/:id/merchandising',
    merchandisingGet: '/client/:id/merchandising',
    merchandisingRandomProduct: '/client/:id/merchandising/random-product',
    merchandisingPostTagCategory: ':lang/client/:id/tag-category',
    merchandisingGetTagCategory: '/client/:id/tag-category',
    merchandisingDeleteTagCategory: '/client/:id/tag-category/:tagCategoryID',
    // COLLECTIONS
    collectionsGetAllCollections: '/brands/:id/collection',
    collectionsGetCollection: '/brands/:id/collection/:collectionID',
    collectionsPostCollection: '/brands/:id/collection',
    collectionsUpdateCollection: '/brands/:id/collection/:collectionID',
    collectionsDeleteCollection: '/brands/:id/collection/:collectionID',
    collectionsUpdateState: '/brands/:id/collection/:collectionID/state/:state',
    collectionsClients: 'client',
    MarketsForclients: 'clients',
    MarketsForclient: 'clients/:brandID',
    // CUSTOM TAGS
    allCustomTagsByBrand:'/brands/:id/custom-tag',
    postCustomTags:'/brands/:brandID/custom-tag',
    deleteCustomTags:'/brands/:id/custom-tag/:customTag',
    allCustomTagsByNameAndBrand: '/brands/:brandID/custom-tag-name/:customTag',
    // ARTICLES
    newArticles: 'new-articles',
    newArticle: 'new-articles/:id',
    putNewArticle: 'new-articles/:id',
    productsEan: '/brands/:brandID/recommerce/merchandising/:ean/:state',
    editArticle: '/brand/:brandID/article/edit/:ean',
    // RECOMMERCE
    merchandisingGetSchema: '/brands/:brandID/recommerce/merchandising',
    merchandisingUpdateSchema: '/brands/:brandID/recommerce/merchandising',
    // NEW RECOMMERCE
    recomResalesStock: 'tradeins-stock',
    recomVouchers: '/giftcards',
    recomVoucher: '/giftcards/:id',
    recomVoucherRefund: '/giftcards/:id/cancel',
    recomResales: 'tradeins',
    recomResale: 'tradeins/:id',
    recomOrders: 'new-orders',
    recomOrder: 'new-orders/:id',
    recomCustomers: 'new-customers',
    recomCustomer: 'new-customers/:id',
    recomCustomerOrders: 'new-customers/:id/orders',
    recomCustomerTradeins: 'new-customers/:id/tradeins',
    recomCustomerTransactions: 'new-customers/:id/transactions',
    recomCustomerWalletTransactionAdd: 'new-customers/:id/transactions',
    recomCustomerResetPassword: 'new-customers/:id/reset-password',
    recomSubscribers: 'marketing-subscription',
    recomSoldArticles: 'new-order-items',
    recomTradeinArticles: 'tradeins-items',
    recomTradeinArticle: 'tradeins-items/:id',
    recomPromoCodes: 'promocodes',
    recomPromoCodesEdit: 'promocodes/:id',
    recomPromoCodesExport: 'promocodes/export',
    recomPromoCodeFilters: 'promocodes/filters',
    shipmentManifest: 'shipment-manifests',

}

export default listRoutes