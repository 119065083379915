const state = {
  filtersSelected: [{ key: null, operator: null, value: null }],
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filtersSelected = filters.length
      ? [...filters]
      : [{ key: null, operator: null, value: null }];
  },
  SET_FILTER(state, data) {
    const { i, field } = data;
    let filters = [...state.filtersSelected];
    if (filters[i]) filters[i] = { ...field };
    state.filtersSelected = filters;
  },
  ADD_FILTER(state) {
    state.filtersSelected = [
      ...state.filtersSelected,
      { key: null, operator: null, value: null },
    ];
  },
  DELETE_FILTER(state, i) {
    state.filtersSelected = [
      ...state.filtersSelected.filter((a, b) => b !== i),
    ];
  },
};

const actions = {
  getFiltersFromUrl({ commit }, data) {
    const { query, filters } = data;
    let selected = [];

    // Traiter d'abord les filtres standards
    for (let key in query) {
        if (!key.includes(":")) continue;
        const [filterKey, operator] = key.split(":");
        if (filterKey === "currency") continue; // Traiter la currency séparément
        
        const filterDef = filters.find(f => f.key === filterKey);
        if (filterDef) {
            let value = query[key];
            
            // Conserver la logique de gestion des selects
            if (filterDef.type === 'select') {
                value = Array.isArray(value) ? value : [value];
            }
            
            selected.push({
                key: filterKey,
                operator,
                value
            });
        }
    }

    // Ajouter la currency si présente
    const currencyKey = Object.keys(query).find(k => k.startsWith("currency:"));
    if (currencyKey) {
        selected.push({
            key: "currency",
            operator: currencyKey.split(":")[1],
            value: query[currencyKey]
        });
    }

    commit("SET_FILTERS", selected);
},
    updateFilter({commit}, data) {
        commit('SET_FILTER', data)
    },
    resetFilters({commit}) {
        commit('SET_FILTERS', {key: 0, operator: null, value: null})
    },
    addFilter({commit}) {
        commit('ADD_FILTER')
    },
    deleteFilter({commit}, i) {
        commit('DELETE_FILTER', i)
    }
};

const getters = {};

const filtres = { state, mutations, actions, getters };

export default filtres;
