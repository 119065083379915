<template>
    <component :is="route ? 'router-link' : 'div'" class="checkerboard-item" :to="route">
        <img v-if="image" :src="image" :alt="title">
        <SvgIcon v-if="icon" :name="icon"/>
        <TextTitle v-if="title" :size="titleSize">{{ title }}</TextTitle>
        <TextSimple v-if="subtitle">{{ subtitle }}</TextSimple>
        <TextSimple v-if="smalltext"><small class="tag">{{ smalltext }}</small></TextSimple>
        <TagStatistic v-if="statistic || statistic === 0" :value="statistic" :color="statisticColor"/>
        <PopinTooltip v-if="info">
            <template v-slot:button>
                <i class="checkerboard-item__infos">i</i>
            </template>
            <template v-slot:content>
                <TextSimple size="xxs">{{ info }}</TextSimple>
            </template>
        </PopinTooltip>
        <slot/>
    </component>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
import TagStatistic from '@/components/ui/tag/TagStatistic';
import PopinTooltip from '@/components/ui/popin/PopinTooltip';
export default {
    name: 'CheckerboardItem',
    components: {PopinTooltip, TagStatistic, TextSimple, TextTitle, SvgIcon},
    props: {
        title: [String, Number],
        icon: String,
        subtitle: [String, null],
        smalltext: String,
        image: String,
        info: String,
        statistic: Number,
        statisticColor: String,
        route: Object,
        titleSize: {
            type: String,
            default: 'm'
        }
    }
}
</script>

<style lang="scss">
.checkerboard-item {
    border-radius: var(--border-radius-1);
    position: relative;
    text-align: center;
    background-color: #fff;
    aspect-ratio: 1;
    padding: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    gap: 15px;
    white-space: break-spaces;

    @media screen and (min-width: 1024px) {
        transition: background-color ease .3s;

        &:hover {
            background-color: var(--color-bg-very-light);
        }
    }

    @media screen and (max-width: 800px) {
        gap: 10px;
    }

    & > img,
    & > .icon svg {
        height: 35px;
        width: 35px;

        @media screen and (max-width: 800px) {
            width: 25px;
            height: 25px;
        }
    }

    .link-underline {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow-x: hidden;
    }

    .button {
        margin-top: 20px;
    }

    &__infos {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--color-bg-grey);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .tooltip {
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 1;
        width: calc(100% - 20px);
        display: flex;
        justify-content: flex-end;
        line-height: 1.3;

        &__button i {
            font-style: normal;
        }

        &__content {
            right: -10px;
            left: auto;
            max-width: none;
            width: 200px;
            bottom: auto;
            top: calc(100% + 10px);

            &:after {
                left: auto;
                right: 15px;
                bottom: auto;
                top: -5px;
            }
        }
    }
}
</style>