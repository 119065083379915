<template>
    <div class="default-layout">
      <header class="default-layout__header">
        <SvgLogo />
        <SvgIcon name="menu" @click.native="displayMenu = true" />
      </header>
  
      <div
        class="default-layout__aside__bg"
        :class="{ 'is-visible': displayMenu }"
        @click="displayMenu = false"
      ></div>
      <aside class="default-layout__aside" :class="{ 'is-visible': displayMenu }">
        <SvgLogo />
        <div class="default-layout__aside__close" @click="displayMenu = false">
          <SvgIcon name="cross" />
        </div>
        <LayoutMenu @close="displayMenu = false" />
        <LayoutProfile />
      </aside>
  
      <main class="default-layout__main">
        <div class="default-layout__heading">
          <TextSimple size="m" weight="bold">{{ user?.organization }}</TextSimple>
          <div v-if="temporaryUser" class="default-layout__temporary-user">
            <div>
              <TextSimple size="s">{{
                $t("temporary_user.title", {
                  firstname: temporaryUser.firstname,
                  lastname: temporaryUser.lastname,
                })
              }}</TextSimple>
              <TextSimple size="xs" color="grey">{{
                temporaryUser.email
              }}</TextSimple>
            </div>
            <ButtonCustom
              @click.native.prevent="unsetTemporaryUser"
              icon="cross"
              color="grey"
            />
          </div>
        </div>
        <div class="default-layout__inner" v-if="clients">
          <slot />
        </div>
        <PageLoader v-else />
      </main>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import SvgLogo from "@/components/ui/svg/SvgLogo";
  import TextSimple from "@/components/ui/text/TextSimple";
  import LayoutMenu from "@/components/ui/layouts/LayoutMenu";
  import LayoutProfile from "@/components/ui/layouts/LayoutProfile";
  import SvgIcon from "@/components/ui/svg/SvgIcon";
  import PageLoader from "@/components/ui/page/PageLoader";
  import ButtonCustom from "@/components/ui/button/Button";
  
  export default {
    name: "DefaultLayout",
    components: {
      ButtonCustom,
      PageLoader,
      SvgIcon,
      LayoutProfile,
      LayoutMenu,
      TextSimple,
      SvgLogo,
    },
    data() {
      return {
        displayMenu: false,
      };
    },
    computed: {
      ...mapState(["user", "clients", "temporaryUser"]),
      ...mapGetters(["isClient"]),
    },
    methods: {
      ...mapActions(["getClients", "unsetTemporaryUser"]),
    },
    mounted() {
      if (!this.clients) this.getClients();
    },
  };
  </script>
  
  <style lang="scss">
  .default-layout {
    position: relative;
    min-height: 100vh;
    background-color: var(--color-bg-light);
  
    @media screen and (min-width: 1025px) {
      display: flex;
    }
  
    &__main {
      padding: 30px var(--page-margin);
      flex-grow: 1;
      position: relative;
      min-width: 0;
      display: flex;
      flex-direction: column;
  
      @media screen and (max-width: 1024px) {
        padding: 0;
      }
    }
  
    &__heading {
      border-bottom: var(--border-1);
      padding-bottom: 30px;
      align-items: baseline;
      display: flex;
      margin-bottom: 30px;
  
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  
    &__aside {
      width: 300px;
      background-color: #fff;
      height: 100vh;
      padding: 15px 0;
      flex-shrink: 0;
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      transition: width ease 0.3s;
  
      @media screen and (min-width: 1025px) {
        overflow: hidden;
  
        .menu {
          overflow: hidden;
          overflow-y: auto;
        }
  
        .menu > ul > li > .layout-menu-link,
        .layout-menu-collapse__button > .layout-menu-link {
          padding: 20px 12px;
        }
  
        .logo {
          width: 160px !important;
          max-width: none !important;
          margin-left: 12px !important;
          margin-top: 15px !important;
        }
  
        &__bg {
          display: none;
        }
  
        .layout-menu-link + .icon,
        .layout-menu-link > .text,
        .menu > .text,
        .logo path:not(:first-of-type) {
          transition: opacity ease 0.3s;
        }
  
        .layout-menu-link {
          max-height: 32px;
        }
  
        .text {
          white-space: nowrap;
        }
  
        .menu__currency {
          margin-bottom: 8px;
          .field-select__items {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
          }
          .field-select__selected {
            padding-left: 12px;
          }
        }
  
        &:not(:hover) {
          width: 70px;
  
          .layout-menu-collapse__content {
            max-height: 0 !important;
          }
  
          .layout-menu-link > .text,
          .layout-menu-link + .icon,
          .menu > .text {
            opacity: 0;
          }
  
          .logo path:not(:first-of-type) {
            opacity: 0;
          }
          .menu__currency {
            .field-select__selected {
              max-width: 40px;
              .field-select__content {
                .field-select__placeholder {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
  
      @media screen and (max-width: 1024px) {
        position: fixed;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 11;
        box-shadow: var(--box-shadow-1);
        transition: transform ease-in-out 0.5s;
  
        &:not(.is-visible) {
          transform: translateX(calc(100% + 40px));
          pointer-events: none;
        }
  
        &__bg {
          display: block;
          z-index: 10;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          position: fixed;
          top: 0;
          left: 0;
          transition: opacity ease-in-out 0.5s;
  
          &:not(.is-visible) {
            opacity: 0;
            pointer-events: none;
          }
        }
  
        .logo {
          display: none;
        }
      }
  
      &__close {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: flex-end;
  
        svg {
          width: 15px;
        }
  
        @media screen and (min-width: 1025px) {
          display: none;
        }
      }
  
      & > * {
        padding-right: 15px;
        padding-left: 15px;
      }
  
      .logo {
        //padding: 7px;
        border-radius: var(--border-radius-1);
        margin: 20px 7px 30px;
        max-width: 140px;
        flex-shrink: 0;
      }
    }
  
    &__header {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      padding: 0 var(--page-margin);
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: var(--height-header);
  
      @media screen and (min-width: 1025px) {
        display: none;
      }
  
      svg {
        height: 15px;
        width: auto;
      }
    }
  
    &__inner {
      flex-grow: 1;
  
      @media screen and (max-width: 1024px) {
        padding: var(--page-margin);
      }
    }
  
    &__temporary-user {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 10px;
      text-align: right;
  
      p {
        margin: 0;
      }
  
      .button {
        min-height: 40px;
        min-width: 40px;
        padding: 0;
      }
    }
  }
  </style>