<template>
    <TemplateDetails
      :get-function="getRecomCustomer"
      :data="customer"
      :loading="customerLoading"
    >
      <PageBack />
  
      <PageHeader :title="$t('customer.recom_title')" v-if="customer">
        <template v-slot:actions>
          <!--TO DO : Function to send emails to customer-->
          <Button v-if="customer.isGuest === false" size="m" @click.native.prevent="sendResetPasswordEmail"
            >{{ $t("customer.btn_send_reinit_email") }}
          </Button>
          <Button v-if="customer.isGuest === true" size="m"  @click.native.prevent="sendResetPasswordEmail"
            >{{ $t("customer.btn_send_activation_email") }}</Button
          >
          <ButtonLink
            v-if="customer.memberAreaUrl"
            :to="customer.memberAreaUrl"
            color="blue2"
            icon="profile"
            size="m"
            target="_blank"
            >{{ $t("customer.btn_profile") }}</ButtonLink
          >
        </template>
      </PageHeader>
  
      <GridContainer>
        <GridCard>
          <GridContent>
            <SectionTableInfos :object="customer" :fields="fields" />
          </GridContent>
        </GridCard>
  
        <template v-slot:aside>
          <GridCard v-if="customer && customer.addresses.length">
            <GridContent v-for="(address, i) in customer.addresses" :key="i">
              <SectionTableInfos
                :object="address"
                :fields="fieldsAddress"
                :title="$t('global.address') + ' ' + (i + 1)"
              />
            </GridContent>
          </GridCard>
          <GridCard v-if="customer && customer.nbOrders">
            <GridContent>
              <SectionTableInfos
                :object="customer"
                :fields="fieldsOrders"
                :title="$t('global.orders')"
              />
            </GridContent>
          </GridCard>
  
          <GridCard v-if="customer && customer.nbReprises">
            <GridContent>
              <SectionTableInfos
                :object="customer"
                :fields="fieldsResales"
                :title="$t('global.resales')"
              />
            </GridContent>
          </GridCard>
        </template>
  
        <template v-slot:bottom>
          <div>
            <GridHeader :title="$t('menu.orders')" :count="orders.length">
              <ButtonLink
                size="m"
                color="black"
                icon="eye"
                :to="{
                  name: 'recom_orders',
                  query: { 'user:eq': customer.email },
                }"
                >{{ $t("customer.btn_orders") }}</ButtonLink
              >
            </GridHeader>
            <TableSticky :fields="ordersFields" :data="orders" />
          </div>
  
          <div>
            <GridHeader :title="$t('menu.resales')" :count="resales.length">
              <ButtonLink
                size="m"
                color="black"
                icon="eye"
                :to="{
                  name: 'recom_resales-all',
                  query: { 'user:eq': customer.email, page: 1, limit: 25 },
                }"
                >{{ $t("customer.btn_resales") }}</ButtonLink
              >
            </GridHeader>
            <TableSticky :fields="resalesFields" :data="resales" />
          </div>
  
          <div>
            <GridHeader :title="$t('menu.credits')" :count="giftCards.length">
              <ButtonLink
                size="m"
                color="black"
                icon="eye"
                :to="{
                  name: 'recom-vouchers',
                  query: { 'user[like]': customer.email },
                }"
                >{{ $t("customer.btn_vouchers") }}</ButtonLink
              >
            </GridHeader>
            <TableSticky :fields="giftCardsFiels" :data="giftCards" />
          </div>
  
          <div>
            <GridHeader :title="$t('menu.cmsCredits')" :count="credits.length">
              <ButtonLink
                v-show="roleIsAllowed(['ROLE_ADMIN_GIFTCARD', 'ROLE_BRAND_SAV'])"
                size="m"
                color="red"
                icon="plus"
                :to="{
                  name: 'recom-wallet-transactions-add',
                  params: { id: customer.id },
                }"
                >{{ $t("customer.add_credit_sav") }}</ButtonLink
              >
              <ButtonLink
                size="m"
                color="black"
                icon="eye"
                :to="{
                  name: 'recom-vouchers',
                  query: {
                    'brand:eq': customer.brand.id,
                    'user:eq': customer.email,
                  },
                }"
                >{{ $t("customer.btn_credits") }}</ButtonLink
              >
            </GridHeader>
            <TableSticky :fields="creditsFields" :data="credits" />
          </div>
        </template>
      </GridContainer>
    </TemplateDetails>
  </template>
  
  <script>
  import API from "@/api/api-admin";
  import SectionTableInfos from "@/components/sections/SectionTableInfos";
  import TemplateDetails from "@/components/templates/TemplateDetails";
  import ButtonLink from "@/components/ui/button/ButtonLink";
  import GridCard from "@/components/ui/grid/GridCard";
  import GridContainer from "@/components/ui/grid/GridContainer";
  import GridContent from "@/components/ui/grid/GridContent";
  import GridHeader from "@/components/ui/grid/GridHeader";
  import PageBack from "@/components/ui/page/PageBack";
  import PageHeader from "@/components/ui/page/PageHeader";
  import TableSticky from "@/components/ui/table-sticky/TableSticky";
  import Button from "@/components/ui/button/Button";
  import { mapActions, mapGetters, mapState } from "vuex";
  
  export default {
    name: "CustomerPage",
    components: {
      GridHeader,
      TableSticky,
      GridContent,
      GridCard,
      GridContainer,
      ButtonLink,
      SectionTableInfos,
      PageHeader,
      PageBack,
      TemplateDetails,
      Button,
    },
    data() {
      return {
        fields: [
          { key: "id" },
          { key: "shopifyId", type: "shopifyCustomer" },
          { key: "firstName" },
          { key: "lastName" },
          { key: "email", type: "copy" },
          { key: "profile" },
          {
            key: "isGuest",
            translationKey: "state",
            type: "isGuest",
            force: true,
          },
          { key: "gender", type: "gender" },
          { key: "brand", type: "client" },
          {
            key: "emailSubscription",
            translationKey: "optinEmail",
            type: "boolean",
          },
          { key: "smsSubscription", translationKey: "optinSMS", type: "boolean" },
          { key: "nbOrders", force: true },
          { key: "nbTradeInCarts", force: true, translationKey: "nbReprises" },
          { key: "createdAt", type: "date" },
          { key: "updatedAt", type: "date" },
          { key: "locale", type: "flag" },
          { key: "countryCode", size: "xs" },
          { key: "memberAreaUrl", type: "memberUrl" },
        ],
        fieldsAddress: [
          { key: "firstName" },
          { key: "lastName" },
          { key: "addressLine1", translationKey: "address1" },
          { key: "addressLine2", translationKey: "address2" },
          { key: "zipCode", translationKey: "zip1" },
          { key: "city" },
          { key: "countryCode" },
        ],
        fieldsOrders: [
          { key: "nbOrders" },
          { key: "lastOrderDate", type: "date" },
        ],
        fieldsResales: [
          { key: "nbReprises" },
          { key: "lastReturnDate", type: "date" },
        ],
        orders: null,
        resales: null,
        credits: null,
        giftCards: null,
        ordersFields: [
          { key: "id", type: "eye", route: "recom_order", size: "xs" },
          { key: "reference", translationKey: "id", size: "m" },
          { key: "createdAt", translationKey: "orderDate", type: "date" },
          { key: "customer.email", type: "userEmail", size: "xl" },
          { key: "channel", translationKey: "canal" },
          { key: "total", type: "formatPrice" },
          { key: "paymentStatus", type: "tagType", size: "m" },
          {
            key: "state",
            translationKey: "orderStatus",
            type: "tagType",
            size: "m",
          },
          { key: "shippingStatus", type: "tagType", size: "m" },
          { key: "returnStatus", type: "tagType", size: "m" },
          { key: "itemsNb", translationKey: "countArticles" },
          { key: "shippingAddress.countryCode" },
        ],
        resalesFields: [
          { key: "id", type: "eye", route: "recom_resale", size: "xs" },
          { key: "brand.name", type: "client", size: "l", noSortable: true },
          { key: "reference", translationKey: "id", size: "m" },
          { key: "customer.email", type: "userEmail", size: "xl" },
          {
            key: "itemsProposedAmount",
            translationKey: "credit_price",
            type: "formatPrice",
          },
          { key: "state", type: "tagType", size: "m" },
          {
            key: "itemsCreditedAmount",
            translationKey: "creditGranted",
            type: "formatPrice",
          },
          { key: "itemsNb", translationKey: "countArticles" },
          { key: "channel", translationKey: "canal" },
          { key: "shippingAddress.countryCode" },
        ],
        creditsFields: [
          {
            key: "createdAt",
            translationKey: "recom_transactionsDate",
            type: "date",
          },
          {
            key: "amount",
            translationKey: "recom_transactionsAmount",
            type: "formatPrice",
          },
          {
            key: "category",
            translationKey: "recom_transactionsType",
            type: "tagTypeCategory",
          },
          {
            key: "reason",
            translationKey: "recom_transactionsReason",
            type: "tagTypeReason",
            size: "xxl",
          },
          {
            key: "description",
            translationKey: "recom_transactionsDescription",
            size: "xxl",
          },
        ],
        giftCardsFiels: [
          { key: "id", type: "eye", route: "recom-voucher", size: "xs" },
          { key: "id", type: "copy", size: "xl" },
          { key: "brand", translationKey: "client", type: "client", size: "l" },
          { key: "amount", type: "formatPrice" },
          { key: "code", type: "editVoucher", size: "l" },
          { key: "createdAt", type: "date", size: "m" },
          {
            key: "expiredAt",
            translationKey: "expiresOn",
            type: "date",
            size: "m",
          },
          {
            key: "type",
            type: "voucherType",
            size: "m",
            translationKey: "channel_of_use",
          },
          {
            key: "state",
            param: "recom_voucherState",
            type: "tagType",
            size: "m",
          },
          {
            key: "burned",
            translationKey: "isBurned",
            type: "boolean",
            size: "m",
          },
          {
            key: "burnedAt",
            translationKey: "burnedAt2",
            type: "date",
            size: "m",
          },
        ],
      };
    },
    computed: {
      ...mapState(["customer", "customerLoading"]),
      ...mapGetters(["roleIsAllowed"]),
    },
    methods: {
      ...mapActions(["getRecomCustomer", "exportTable", "setNotificationMessage"]),
      getOrders() {
        API.get(
          `${this.$listRoutes.recomCustomerOrders}`.replace(
            ":id",
            this.customer.id
          )
        ).then((res) => (this.orders = res.data));
      },
      getResales() {
        API.get(
          `${this.$listRoutes.recomCustomerTradeins}`.replace(
            ":id",
            this.customer.id
          )
        ).then((res) => (this.resales = res.data));
      },
      getCredits() {
        API.get(
          `${this.$listRoutes.recomCustomerTransactions}`.replace(
            ":id",
            this.customer.id
          )
        ).then((res) => (this.credits = res.data));
      },
      getGiftCards() {
        API.get(
          `${this.$listRoutes.recomVouchers.replace(
            ":id",
            this.customer.id
          )}?page=1&limit=100&user[like]=${window.encodeURIComponent(
            this.customer.email
          )}`
        ).then((res) => (this.giftCards = res.data?.items));
      },
      sendResetPasswordEmail() {
      API.post(`${this.$listRoutes.recomCustomerResetPassword.replace(':id', this.customer.id)}`, {})
        .then((response) => {
          const message = response.data?.message || this.$t("customer.reset_password_success");
          this.setNotificationMessage(message); // utilisez l'action existante
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || this.$t("customer.reset_password_error");
          this.setNotificationMessage(errorMessage); // utilisez l'action existante
        });
    },
    },
    watch: {
      customer() {
        if (this.customer) {
          this.getOrders();
          this.getResales();
          this.getCredits();
          this.getGiftCards();
        }
      },
    },
    mounted() {
      if (this.customer) {
        this.getOrders();
        this.getResales();
        this.getCredits();
        this.getGiftCards();
      }
    },
  };
  </script>
  